const colorDark = "#222";
const colorDark2 = "#666";
const colorGray = "#e3e3e3";
const colorWhite = "#fff";
const black = "black";
const bgGray = "#d8d8d8";
const styles = {
  dark: {
    color: colorDark,
  },

  white: {
    color: colorWhite,
  },

  "bg-dark": {
    backgroundColor: colorDark2,
  },
  tableBody: {
    width: "545px",
  },
  tableHead: {
    width: "545px",
  },
  "bg-gray": {
    backgroundColor: bgGray,
  },
  block: {
    display: "block",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  flexWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  flexBetween: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  "w-auto": {
    flex: 1,
    paddingRight: "8px",
  },

  "pl-0": {
    paddingLeft: "0px",
  },
  "ml-30": {
    flex: 1,
  },
  "h-18": {
    height: "18px",
  },
  "h-20": {
    height: "20px",
  },
  "h-24": {
    height: "24px",
  },
  "h-35": {
    height: "35px",
  },
  "w-100": {
    width: "100%",
  },
  "w-50": {
    // width: "50%",
    width: "270px",
  },
  "w-amount": {
    width: "70px",
  },
  "w-55": {
    width: "55%",
  },

  "w-45": {
    width: "45%",
  },

  "w-60": {
    width: "60%",
  },

  "w-40": {
    width: "40%",
  },

  "w-48": {
    width: "48%",
  },

  "w-32": {
    // width: "32%",
    width: "175px",
  },
  "w-10": {
    // width: "14%",
    width: "55px",
  },
  "w-12": {
    // width: "14%",
    width: "66px",
  },
  "w-14": {
    // width: "14%",
    width: "77px",
  },
  "w-17": {
    width: "17%",
  },
  "w-18": {
    // width: "18%",
    width: "98px",
  },

  "mt-40": {
    marginTop: "40px",
  },

  "mt-30": {
    marginTop: "30px",
  },

  "mt-20": {
    marginTop: "20px",
  },

  "mt-10": {
    marginTop: "10px",
  },
  "mb-8": {
    marginBottom: "8px",
  },
  "mb-5": {
    marginBottom: "5px",
  },
  "mb-2.5": {
    marginBottom: "2.5px",
  },
  "ml-271": {
    marginLeft: "270px",
  },
  "mx-15": {
    marginLeft: "15px",
    marginRight: "15px",
  },
  "ml-15": {
    marginLeft: "15px",
  },
  "p-4-8": {
    padding: "4px 8px",
  },

  "p-5": {
    padding: "5px",
  },

  "pb-10": {
    paddingBottom: "10px",
  },
  border: {
    border: "1px solid",
    borderColor: black,
  },
  borderRight: {
    borderRight: "1px solid ",
    borderColor: black,
  },
  borderLeft: {
    borderLeft: "1px solid ",
    borderColor: black,
  },
  borderBottom: {
    borderBottom: "1px solid ",
    borderColor: black,
  },
  right: {
    textAlign: "right",
  },
  left: {
    textAlign: "left",
  },
  bold: {
    fontWeight: "bold",
  },
  logoWrap: {
    position: "relative",
    height: "46px",
    display: "block",
    objectFit: "contain",

    textAlign: "left",
    marginLeft: "0px",
  },
  logo: {
    height: "42px",
    display: "block",
    objectFit: "contain",
    objectPosition: "left top",
    top: 0,
    left: "15px",
    maxWidth: "242px",
    marginLeft: "0px",
  },
  block: {
    display: "block",
  },
  signature: {
    top: 0,
    left: "120px",
    height: "56px",
    width: "56px",
    display: "block",
    objectFit: "contain",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",

    objectFit: "contain",
  },
  "fs-20": {
    fontSize: "20px",
  },

  "fs-45": {
    fontSize: "45px",
  },

  page: {
    fontFamily: "Nasu-Regular",
    fontSize: "11px",
    color: "black",
    padding: "20px 25px",
  },

  span: {
    padding: "4px 12px 4px 0",
  },
  title: {
    fontSize: "28px",
    textAlign: "center",
    width: "100%",
    display: "block",
  },
  "f-9": {
    fontSize: "9px",
  },
  "f-10": {
    fontSize: "10px",
  },
  "f-11": {
    fontSize: "11px",
    verticalAlign: "middle",
  },
  "f-12": {
    fontSize: "12px",
  },
  "f-14": {
    fontSize: "14px",
  },
  "f-13": {
    fontSize: "13px",
  },
  "f-16": {
    fontSize: "16px",
  },
  "f-18": {
    fontSize: "18px",
  },
  "f-24": {
    fontSize: "24px",
  },

  // logo: {
  //   display: "block",
  // },
  view: {
    breakInside: "avoid",
  },
  "col-3": {
    flex: "0 0 25%",
    maxWidth: "25%",
    position: "relative",
    width: "100%",
    paddingRight: "15px",
    paddingLeft: "15px",
  },
  "pl-10": {
    paddingLeft: "10px",
  },
  "col-6": {
    flex: "0 0 50%",
    maxWidth: "50%",
    position: "relative",
    width: "287px",
    // paddingRight: "15px",
    // paddingLeft: "15px",
  },
  "w-250": {
    width: "250px",
  },
  "w-287": {
    width: "287px",
  },
  "col-9": {
    flex: "0 0 75%",
    maxWidth: "75%",
    position: "relative",
    width: "100%",
    paddingRight: "15px",
    paddingLeft: "15px",
  },
  "col-12": {
    flex: "0 0 100%",
    maxWidth: "100%",
    position: "relative",
    width: "100%",
    paddingRight: "15px",
    paddingLeft: "15px",
  },
  "m--1": {
    marginTop: "-1px",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    marginRight: "-15px",
    marginLeft: "-15px",
  },
  thead: {},
  theadTitle: {
    backgroundColor: bgGray,
    textAlign: "center",
    color: "black",
    fontWeight: "bold",
    padding: "4px 8px",
    fontSize: "11px",
  },
  smTheadTitle: {
    backgroundColor: bgGray,
    textAlign: "center",
    color: "black",
    fontWeight: "bold",
    padding: "4px 4px",
    fontSize: "10px",
  },
  tBodyRow: {
    // border: "1px solid black",
    // marginTop: "-1px",
  },
  td: {
    textAlign: "center",
    padding: "2px 8px 0",
    fontSize: "11px",
    minHeight: "20px",
  },
  "sm-td": {
    padding: "3px 8px",
    fontSize: "9px",
    height: "16px",
  },
  textArea: {
    minHeight: "40px",
    width: "545px",
    padding: 0,
  },
  bt: {
    borderTop: "1px solid black",
  },
  bb: {
    borderBottom: "1px solid black",
  },
  br: {
    borderRight: "1px solid black",
  },
  bl: {
    borderLeft: "1px solid black",
  },
  "flex-grow-1": {
    flexGrow: 1,
  },
};

export default styles;
