import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Badge,
} from "reactstrap";
import Dropzone from "components/Dropzone/Dropzone";
import { useForm, Controller } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styles from "./InvoiceForm.module.scss";
import { db, useAuth, storageRef } from "../../firebase";
import { getDownloadURL } from "firebase/storage";

const InvoiceForm = ({
  type,
  invoiceNumber,
  initialData,
  register,
  errors,
  subTotal,
  taxTotal,
  total,
  items,
  setItems,
  setLogoFile,
  setSignatureFile,
}) => {
  const [logoPreSignedUrl, setLogoPreSignedUrl] = useState();
  const [signaturePreSignedUrl, setSignaturePreSignedUrl] = useState();

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(items);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setItems(tempData);
  };

  const deleteRow = (id) => {
    const arr = items.filter((d) => id !== d.id);
    setItems(arr);
  };

  const handleChangeInput = (title, value, index) => {
    let tmp = [...items];
    const nums = ["amount", "unitPrice"];
    if (nums.includes(title)) {
      tmp[index][title] = Number(value);
    } else {
      tmp[index][title] = value;
    }

    setItems([...tmp]);
  };

  const handleAddNewItem = () => {
    const date = new Date();
    const time = date.getTime();
    setItems([
      ...items,
      {
        id: `${time}`,
        title: "",
        amount: null,
        unit: "",
        unitPrice: null,
        taxRate: 10,
      },
    ]);
  };

  const getPresignedUrl = async (path) => {
    const tmp = await storageRef(path);

    const pre = await getDownloadURL(tmp);

    return pre;
  };
  useEffect(async () => {
    if (initialData?.logoUrl) {
      const logoPresignedUrl = await getPresignedUrl(initialData.logoUrl);
      setLogoPreSignedUrl(logoPresignedUrl);
    }
  }, [initialData?.logoUrl]);
  useEffect(async () => {
    if (initialData?.signatureUrl) {
      const signaturePresignedUrl = await getPresignedUrl(
        initialData.signatureUrl
      );
      setSignaturePreSignedUrl(signaturePresignedUrl);
    }
  }, [initialData?.signatureUrl]);
  return (
    <>
      <Row>
        <Col lg="6">
          <h6 className="heading-small text-muted mb-4">基本情報</h6>
          <div className="">
            <Row>
              <Col lg="9">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    取引先
                    <Badge className="ml-2" color="default">
                      必須
                    </Badge>
                  </label>
                  <input
                    className={`form-control ${
                      errors?.toName?.message ? "border-danger" : ""
                    }`}
                    id="toName"
                    placeholder="会社名/屋号名"
                    defaultValue={initialData?.toName}
                    type="text"
                    {...register("toName", {
                      required: {
                        value: true,
                        message: "会社名/屋号名は入力必須項目です。",
                      },
                    })}
                  />
                  {errors?.toName?.message && (
                    <span className="text-red">{errors.toName.message}</span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label" htmlFor="honorific">
                    敬称
                  </label>
                  <select
                    id="honorific"
                    className="form-control"
                    type="select"
                    defaultValue={initialData?.honorific}
                    {...register("honorific")}
                  >
                    <option value="御中">御中</option>
                    <option value="様">様</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="invoiceNumber">
                    {type === "invoice" ? "請求書番号" : "見積書番号"}
                  </label>
                  <input
                    className="form-control"
                    id="invoiceNumber"
                    placeholder="00000000"
                    type="text"
                    defaultValue={invoiceNumber}
                    {...register("invoiceNumber", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="issueDate">
                    発行日
                  </label>
                  <input
                    className="form-control"
                    defaultValue={initialData?.issueDate}
                    id="issueDate"
                    type="date"
                    {...register("issueDate", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="payDate">
                    {type === "invoice" ? "お支払い期限" : "有効期限"}
                  </label>
                  <input
                    className="form-control"
                    defaultValue={initialData?.payDate}
                    id="payDate"
                    type="date"
                    {...register("payDate", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="title">
                    件名
                    <Badge className="ml-2" color="default">
                      必須
                    </Badge>
                  </label>
                  <input
                    className={`form-control ${
                      errors?.title?.message && "border-danger"
                    }`}
                    id="title"
                    placeholder="件名を入力してください"
                    type="text"
                    defaultValue={initialData?.title}
                    {...register("title", {
                      required: {
                        value: true,
                        message: "件名は入力必須項目です。",
                      },
                    })}
                  />
                  {errors?.title?.message && (
                    <span className="text-red">{errors.title.message}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Col>

        <Col lg="6" className="border-md-left border-light">
          <h6 className="heading-small text-muted mb-4">
            {type === "invoice" ? "請求元情報" : "見積元情報"}
          </h6>
          <div className="">
            <Row>
              <Col lg="9">
                <FormGroup>
                  <label className="form-control-label" htmlFor="fromName">
                    氏名
                  </label>
                  <input
                    className={`form-control ${
                      errors?.fromName?.message && "border-danger"
                    }`}
                    id="fromName"
                    placeholder="例）山田　太郎"
                    type="text"
                    defaultValue={initialData?.fromName}
                    {...register("fromName", {
                      required: false,
                    })}
                  />
                  {errors?.fromName?.message && (
                    <span className="text-red">{errors.fromName.message}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="registerNumber"
                  >
                    登録番号
                  </label>
                  <input
                    className="form-control"
                    id="registerNumber"
                    placeholder="T00000000"
                    type="text"
                    defaultValue={initialData?.registerNumber}
                    {...register("registerNumber", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="fromCompanyName"
                  >
                    会社名/屋号名
                  </label>
                  <input
                    className="form-control"
                    id="fromCompanyName"
                    placeholder="例) 株式会社Chott"
                    type="text"
                    defaultValue={initialData?.fromCompanyName}
                    {...register("fromCompanyName", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="fromDepartment"
                  >
                    部署/所属
                  </label>
                  <input
                    defaultValue={initialData?.fromDepartment}
                    className="form-control"
                    id="fromDepartment"
                    placeholder="例) 開発部署"
                    type="text"
                    {...register("fromDepartment", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="postCode">
                    郵便番号
                  </label>
                  <input
                    defaultValue={initialData?.postCode}
                    className="form-control"
                    id="postCode"
                    placeholder="例) 000-0000"
                    type="text"
                    {...register("postCode", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="address1">
                    住所
                  </label>
                  <input
                    className="form-control"
                    id="address1"
                    defaultValue={initialData?.address1}
                    placeholder="例) 東京都渋谷区〇〇"
                    type="text"
                    {...register("address1", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="address2">
                    ビル名/階/部屋番号など
                  </label>
                  <input
                    className="form-control"
                    id="address2"
                    placeholder=""
                    defaultValue={initialData?.address2}
                    type="text"
                    {...register("address2", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="tel">
                    TEL
                  </label>
                  <input
                    className="form-control"
                    id="tel"
                    defaultValue={initialData?.tel}
                    placeholder="例) 03-0000-0000"
                    type="text"
                    {...register("tel", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="fax">
                    FAX
                  </label>
                  <input
                    className="form-control"
                    id="fax"
                    defaultValue={initialData?.fax}
                    placeholder="例) 03-0000-0000"
                    type="text"
                    {...register("fax", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="mail">
                    メール
                  </label>
                  <input
                    className="form-control"
                    id="mail"
                    defaultValue={initialData?.mail}
                    placeholder="例) info@invoicy-cloud.com"
                    type="text"
                    {...register("mail", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="logo">
                    ロゴ
                  </label>
                  <Dropzone
                    id="logo"
                    setData={setLogoFile}
                    defaultValue={logoPreSignedUrl}
                  />
                </FormGroup>
              </Col>
              <Col lg="12">
                <label className="form-control-label" htmlFor="signature">
                  印影
                </label>
                <Dropzone
                  id="signature"
                  setData={setSignatureFile}
                  defaultValue={signaturePreSignedUrl}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <hr className="my-4" />

      {/* <h6 className="heading-small text-muted mb-4">品目明細</h6> */}
      <div className="mb-4">
        <Row>
          <Col md="12">
            <div className="table-responsive">
              <DragDropContext onDragEnd={handleDragEnd}>
                <Table
                  className={`align-items-center table-flush ${styles.tableDetail}`}
                  bordered={true}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">品目名</th>
                      <th scope="col">数量</th>
                      <th scope="col">単位</th>
                      <th scope="col">単価</th>
                      <th scope="col">税区分</th>
                      <th scope="col">金額</th>
                    </tr>
                  </thead>
                  <Droppable droppableId="droppable-1">
                    {(provider) => (
                      <tbody
                        className="list"
                        ref={provider.innerRef}
                        {...provider.droppableProps}
                      >
                        {items.length > 0 &&
                          items.map((item, i) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={i}
                            >
                              {(provider) => (
                                <tr
                                  key={i}
                                  {...provider.draggableProps}
                                  ref={provider.innerRef}
                                  className={styles.draggable}
                                >
                                  <td {...provider.dragHandleProps}>
                                    <div className={styles.dragMenu}>
                                      <span>
                                        <i className="fas fa-grip-lines"></i>
                                      </span>
                                      <span onClick={() => deleteRow(item.id)}>
                                        <i className="fas fa-trash-alt"></i>
                                      </span>
                                    </div>

                                    <Input
                                      placeholder="品目名"
                                      className={styles.itemName}
                                      type="text"
                                      onChange={(e) =>
                                        handleChangeInput(
                                          "title",
                                          e.target.value,
                                          i
                                        )
                                      }
                                      value={item.title}
                                    />
                                  </td>
                                  <td>
                                    <Input
                                      placeholder=""
                                      type="number"
                                      className="text-right"
                                      onChange={(e) =>
                                        handleChangeInput(
                                          "amount",
                                          e.target.value,
                                          i
                                        )
                                      }
                                      value={item.amount}
                                    />
                                  </td>
                                  <td>
                                    <Input
                                      placeholder=""
                                      type="text"
                                      onChange={(e) =>
                                        handleChangeInput(
                                          "unit",
                                          e.target.value,
                                          i
                                        )
                                      }
                                      value={item.unit}
                                    />
                                  </td>
                                  <td>
                                    <Input
                                      placeholder="0"
                                      type="number"
                                      className="text-right"
                                      onChange={(e) =>
                                        handleChangeInput(
                                          "unitPrice",
                                          e.target.value,
                                          i
                                        )
                                      }
                                      value={item.unitPrice}
                                    />
                                  </td>
                                  <td>
                                    <Input
                                      type="select"
                                      onChange={(e) =>
                                        handleChangeInput(
                                          "taxRate",
                                          e.target.value,
                                          i
                                        )
                                      }
                                      value={item.taxRate}
                                    >
                                      <option value={10}>10%</option>
                                      <option value={8}>8%</option>
                                      <option value={0}>対象外</option>
                                    </Input>
                                  </td>
                                  <td>
                                    <Input
                                      placeholder="0"
                                      type="text"
                                      className="text-right"
                                      value={(
                                        item.amount * item.unitPrice +
                                        Math.floor(
                                          item.amount *
                                            item.unitPrice *
                                            (item.taxRate / 100)
                                        )
                                      ).toLocaleString()}
                                      disabled
                                    />
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                        {provider.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                  <tfoot>
                    <tr>
                      <td colSpan="5" className="align-top">
                        <Button
                          className="btn-icon"
                          color="primary"
                          type="button"
                          onClick={handleAddNewItem}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus-square"></i>
                          </span>
                          <span className="btn-inner--text">品目行を追加</span>
                        </Button>
                      </td>
                      <td colSpan="2">
                        <div className="d-flex justify-content-between">
                          <span>小計</span>
                          <span>{subTotal.toLocaleString()}円</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span>消費税</span>
                          <span>{taxTotal.toLocaleString()}円</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span>合計</span>
                          <span>{total.toLocaleString()}円</span>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </DragDropContext>
            </div>
          </Col>
        </Row>
      </div>

      <div className="">
        <FormGroup>
          <label className="form-control-label" htmlFor="note">
            備考
          </label>
          <textarea
            id="note"
            className="form-control"
            placeholder=""
            rows="4"
            defaultValue={initialData?.note}
            type="textarea"
            {...register("note", {
              required: false,
            })}
          />
        </FormGroup>
      </div>
      {type === "invoice" ? (
        <div className="">
          <FormGroup>
            <label className="form-control-label" htmlFor="payee">
              振込先
            </label>
            <textarea
              id="payee"
              className="form-control"
              placeholder=""
              rows="4"
              defaultValue={initialData?.payee}
              type="textarea"
              {...register("payee", {
                required: false,
              })}
            />
          </FormGroup>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default InvoiceForm;
