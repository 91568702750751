import React, { useState, useEffect, useContext } from "react";

import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  CardBody,
  Table,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { db, useAuth } from "../../../firebase";
import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  where,
  query,
} from "firebase/firestore";
import { AuthContext } from "../../../providers/AuthProvider";
import Paginations from "components/Paginations/Paginations";
import { chunkArrayInGroups, formatDate } from "utils/common";
function EstimatesList() {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState();
  const context = useContext(AuthContext);
  const [totalPageNum, setTotalPageNum] = useState(1);
  const [currPage, setCurrPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [invoiceList, setInvoiceList] = useState([]);
  const [listPerPage, setListPerPage] = useState([]);
  const fetchInvoiceData = async () => {
    const estimatesCollectionRef = collection(
      db,
      "docs",
      currentUser.uid,
      "estimates"
    );
    const q = query(estimatesCollectionRef);

    const querySnapshot = await getDocs(q);
    let tmpDocs = [];
    querySnapshot.forEach((doc) => {
      const d = doc.data();
      tmpDocs.push(d);
    });

    const newAry = chunkArrayInGroups(tmpDocs, 15);
    if (newAry.length > 0) {
      setTotalPageNum(newAry.length);
      setInvoiceList(newAry);
      setListPerPage(newAry[0]);
    }
  };

  const handleChangePage = (pageNum) => {
    setListPerPage(invoiceList[pageNum - 1]);
    setCurrPage(pageNum);
  };

  useEffect(() => {
    if (currentUser) {
      fetchInvoiceData();
    }
  }, [currentUser]);

  //ログイン情報格納処理
  useEffect(() => {
    if (!context?.userInfo?.uid) {
      return <></>;
    }
    setCurrentUser(context.userInfo);
  }, [context]);

  const linkTo = () => {
    history.push({
      pathname: "/estimates/new",
      state: {
        number: invoiceList.length + 1,
      },
    });
  };

  return (
    <AdminLayout>
      <Container className="mt-6 mt-xl-2" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="6">
                    <h3 className="mb-0">見積書一覧</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    {/* <Button color="warning" size="sm" onClick={() => testMail()}>
                  test
                </Button> */}

                    <Button onClick={linkTo} color="primary" size="">
                      <span>新規作成</span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              {invoiceList.length > 0 ? (
                <>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="align-items-center table-flush table-hover">
                        <thead className="thead-light">
                          <tr>
                            <th className="col-sm-6" scope="col">
                              文章
                            </th>
                            <th scope="col">請求日</th>
                            <th scope="col">お支払い期限</th>
                            <th scope="col">金額</th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {listPerPage.map((d, i) => (
                            <tr
                              key={i}
                              onClick={() =>
                                history.push(`/estimates/${d.id}/review`)
                              }
                            >
                              <th scope="row">
                                <p className="mb-0">{d.title}</p>
                              </th>
                              <td className="budget">
                                {d?.payDate ? formatDate(d.payDate) : ""}
                              </td>
                              <td className="budget">
                                {d?.issueDate ? formatDate(d.issueDate) : ""}
                              </td>
                              <td className="text-right">
                                {d?.total
                                  ? `${d.total.toLocaleString()}円`
                                  : ""}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <nav aria-label="...">
                      <Paginations
                        pageTotalNum={totalPageNum}
                        pageCurrNum={currPage}
                        onClick={handleChangePage}
                      />
                    </nav>
                  </CardBody>
                </>
              ) : (
                <CardBody>
                  <p>見積書がありません</p>
                </CardBody>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </AdminLayout>
  );
}

export default EstimatesList;
