import React, { useEffect, useState } from "react";
import compose from "../../styles/compose";
import { chunkArrayInGroups, formatDate } from "utils/common";

import {
  Text,
  View,
  PDFDownloadLink,
  Page,
  Document,
  Font,
  Image,
} from "@react-pdf/renderer";
import { getDownloadURL } from "firebase/storage";
import { db, storageRef, useAuth } from "../../firebase";

import fontRegular from "../../fonts/Nasu-Regular.ttf";
Font.register({
  family: "Nunito",
  fonts: [
    { src: "https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaE.ttf" },
    {
      src: "https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUYevN.ttf",
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: "Nasu-Regular",
  src: fontRegular,
});

Font.register({
  family: "NotoSansJP",
  fonts: [
    {
      src: "https://fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-black-webfont.ttf",
    },
  ],
});

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-black-webfont.ttf",
    },
  ],
});

const Invoice = ({ data, title }) => {
  const [loading, setLoading] = useState(true);
  const [logoPreSignedUrl, setLogoPreSignedUrl] = useState();
  const [signaturePreSignedUrl, setSignaturePreSignedUrl] = useState();
  const getPresignedUrl = async (path) => {
    const tmp = await storageRef(path);
    const pre = await getDownloadURL(tmp);

    return pre;
  };
  useEffect(async () => {
    if (data?.signatureUrl) {
      const tmpsignaturePresignedUrl = await getPresignedUrl(data.signatureUrl);
      setSignaturePreSignedUrl(tmpsignaturePresignedUrl);
    }
  }, [data?.signatureUrl]);

  useEffect(async () => {
    try {
      if (data?.logoUrl) {
        const tmplogoPresignedUrl = await getPresignedUrl(data.logoUrl);
        setLogoPreSignedUrl(tmplogoPresignedUrl);
        console.log(tmplogoPresignedUrl);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [data?.logoUrl]);

  if (loading || !data) {
    return <></>;
  }

  console.log();
  return (
    <Document pdfMode={true} renderMode="canvas">
      <Page size="A4" style={compose("page invoice-wrapper")} pdfMode={true}>
        <View style={compose("view flexWrap")}>
          <Text style={compose("f-10 w-100 right mb-2.5")}>
            {data?.issueDate ? formatDate(data?.issueDate) : ""}
          </Text>
          <Text style={compose("f-10 w-100 right")}>
            {data?.invoiceNumber ? `${title}番号:${data?.invoiceNumber}` : ""}
          </Text>
        </View>
        <Text style={compose("title")}>{title}</Text>
        <View style={compose("view row flex mt-10")}>
          <View style={compose("view w-287")}>
            <View style={compose("view flex")}>
              <View style={compose("view flex w-100")}>
                <Text style={compose("f-11 ml-15 mb-2.5")}>
                  {data?.toName}　{data?.honorific}
                </Text>
              </View>
            </View>
            <View style={compose("view flex")}>
              <View style={compose("view flex w-100 mb-2.5")}>
                <Text style={compose("f-11 ml-15")}>件名：{data?.title}</Text>
              </View>
            </View>
            <View style={compose("view flex")}>
              <View style={compose("view flex w-100 mb-2.5")}>
                <Text style={compose("f-11 ml-15")}>
                  下記の通りご請求申し上げます。
                </Text>
              </View>
            </View>
            <View
              style={compose("view w-250 ml-15 flexBetween bg-gray mb-2.5")}
            >
              <Text style={compose("f-11 p-4-8")}>ご請求金額</Text>
              <Text style={compose("f-11 p-4-8")}>
                {data?.total ? `￥${data?.total.toLocaleString()}円` : ""}
              </Text>
            </View>
            <View style={compose("view flex")}>
              <View style={compose("view flex w-100 h-35")}>
                <Text style={compose("f-11 ml-15")}>
                  {data?.payDate
                    ? `お支払い期限：${formatDate(data.payDate)}`
                    : ""}
                </Text>
                <Text style={compose("f-11")}></Text>
              </View>
            </View>
          </View>
          <View style={compose("view w-287 pl-10")}>
            <View style={compose("view flex w-100")}>
              {data?.logoUrl && logoPreSignedUrl ? (
                <View style={compose("view w-100 mb-2.5 logoWrap")}>
                  <Image style={compose("logo")} src={logoPreSignedUrl} />

                  <img
                    style={compose("logo absolute")}
                    src={logoPreSignedUrl}
                  />
                </View>
              ) : (
                <></>
              )}
            </View>
            <View style={compose("view flex w-100")}>
              <View style={compose("view flex w-100")}>
                <View style={compose("view mb-2.5 relative")}>
                  <Text style={compose("f-11 ml-15 block")}>
                    {data?.fromCompanyName ? data.fromCompanyName : ""}
                  </Text>
                  {data?.signatureUrl && signaturePreSignedUrl ? (
                    <>
                      <Image
                        style={compose("signature absolute")}
                        src={signaturePreSignedUrl}
                      />

                      <img
                        style={compose("signature absolute")}
                        src={signaturePreSignedUrl}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </View>
              </View>
              <View style={compose("view flex w-100 mb-2.5")}>
                <Text style={compose("f-11 ml-15 block")}>
                  {data?.fromDepartment ? data.fromDepartment : ""}
                </Text>
              </View>
              <View style={compose("view flex w-100 mb-2.5")}>
                <Text style={compose("f-11 ml-15 block")}>
                  {data?.fromName ? data.fromName : ""}
                </Text>
              </View>
              <View style={compose("view flex w-100 mb-8")}>
                <Text style={compose("f-11 ml-15 block")}>
                  {data?.registerNumber
                    ? `登録番号：${data.registerNumber}`
                    : ""}
                </Text>
              </View>
            </View>
            <View style={compose("view  flex w-100")}>
              <View style={compose("view flex w-100 mb-2.5")}>
                <Text style={compose("f-11 ml-15 block")}>
                  {data.postCode ? `〒${data.postCode}` : ""}
                </Text>
              </View>
              <View style={compose("view flex w-100 mb-2.5")}>
                <Text style={compose("f-11 ml-15 block")}>
                  {data?.address1 ? data.address1 : ""}
                </Text>
              </View>
              <View style={compose("view flex w-100 mb-8")}>
                <Text style={compose("f-11 ml-15 block")}>
                  {data?.address2 ? data.address2 : ""}
                </Text>
              </View>
            </View>

            <View style={compose("view flex w-100")}>
              <View style={compose("view flex w-100 mb-2.5")}>
                <Text style={compose("f-11 ml-15 block")}>
                  {data.tel ? `TEL：${data.tel}` : ""}
                </Text>
              </View>
              <View style={compose("view flex w-100 mb-2.5")}>
                <Text style={compose("f-11 ml-15 block")}>
                  {data.fax ? `FAX：${data.fax}` : ""}
                </Text>
              </View>
              <View style={compose("view flex w-100 mb-2.5")}>
                <Text style={compose("f-11 ml-15 block")}>
                  {data.mail ? `Email：${data.mail}` : ""}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={compose("view flex mt-20 ")}>
          <View style={compose("view tableHead")}>
            <View style={compose("view flex bb bl br bt")} wrap={false}>
              <View style={compose("view w-50 theadTitle br")}>
                <Text>品目</Text>
              </View>
              <View style={compose("view w-14 theadTitle br")}>
                <Text>数量</Text>
              </View>
              <View style={compose("view w-18 theadTitle br")}>
                <Text>単価</Text>
              </View>
              <View style={compose("view w-18 theadTitle")}>
                <Text>金額</Text>
              </View>
            </View>
          </View>

          <View style={compose("view tableBody")}>
            {data.items.length &&
              data?.items.map((item, index) => (
                <View
                  style={compose("view flex bl bt br bb m--1")}
                  wrap={false}
                  key={index}
                >
                  <View style={compose("view w-50 td left br")}>
                    <Text style={compose("f-11")}>{item?.title}</Text>
                  </View>
                  <View style={compose("view w-14 td right br")}>
                    <Text style={compose("f-11")}>
                      {item?.amount}
                      {item?.unit}
                    </Text>
                  </View>
                  <View style={compose("view w-18 td right br")}>
                    <Text style={compose("f-11")}>
                      {item?.unitPrice &&
                        Number(item?.unitPrice).toLocaleString()}
                    </Text>
                  </View>
                  <View style={compose("view w-18 td right")}>
                    <Text style={compose("f-11")}>
                      {item?.amount && item?.unitPrice
                        ? `${(item?.amount * item?.unitPrice).toLocaleString()}`
                        : ""}
                    </Text>
                  </View>
                </View>
              ))}
            {data?.items?.length < 5 &&
              [...Array(5 - data?.items?.length)].map((_, i) => (
                <View
                  key={`e-${i}`}
                  style={compose("view flex bl br bb m--1")}
                  wrap={false}
                >
                  <View style={compose("view w-50 td left br ")}>
                    <Text>　</Text>
                  </View>
                  <View style={compose("view w-14 td right br")}>
                    <Text></Text>
                  </View>
                  <View style={compose("view w-18 td right br")}>
                    <Text></Text>
                  </View>
                  <View style={compose("view w-18 td right")}>
                    <Text></Text>
                  </View>
                </View>
              ))}
            <View
              style={compose("view flex br bl bb bt m--1 ml-271")}
              wrap={false}
            >
              <View style={compose("view w-32 td left br")}>
                <Text>小計</Text>
              </View>

              <View style={compose("view w-18 td right ")}>
                <Text>
                  {data?.subTotal ? `${data.subTotal.toLocaleString()}円` : ""}
                </Text>
              </View>
            </View>
            <View
              style={compose("view flex br bl bb bt m--1 ml-271")}
              wrap={false}
            >
              <View style={compose("view w-32 td left br")}>
                <Text>消費税</Text>
              </View>

              <View style={compose("view w-18 td right ")}>
                <Text>
                  {data?.taxTotal ? `${data.taxTotal.toLocaleString()}円` : ""}
                </Text>
              </View>
            </View>
            <View
              style={compose("view flex br bl bb bt m--1 ml-271")}
              wrap={false}
            >
              <View style={compose("view w-32 td left br")}>
                <Text>合計</Text>
              </View>

              <View style={compose("view w-18 td right ")}>
                <Text>
                  {data?.total ? `${data.total.toLocaleString()}円` : ""}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {data?.note ? (
          <View style={compose("view flex mt-20")}>
            <View style={compose("view")}>
              <View style={compose("view flex")}>
                <View style={compose(" w-100 p-5 textArea")}>
                  <Text style={compose("f-11")}>{data?.note}</Text>
                </View>
              </View>
            </View>
          </View>
        ) : (
          <></>
        )}

        {data?.payee ? (
          <View style={compose("view flex")}>
            <View style={compose("view")}>
              <View style={compose("view flex")}>
                <Text style={compose("f-11")}>お振込先:</Text>
              </View>
              <View style={compose("view flex")}>
                <View style={compose("view w-100 p-5 textArea")}>
                  <Text style={compose("f-11")}>{data?.payee}</Text>
                </View>
              </View>
            </View>
          </View>
        ) : (
          <></>
        )}
      </Page>
    </Document>
  );
};

export default Invoice;
