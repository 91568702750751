import React, { useState, useEffect, useContext } from "react";

import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardImgOverlay,
  CardTitle,
  Collapse,
} from "reactstrap";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import IndexFooter from "components/Footers/IndexFooter.js";
import styles from "./Index.module.scss";
function Index() {
  const [openedCollapse, setOpenedCollapse] = useState("collapse-1");
  return (
    <>
      <IndexNavbar />
      <div className="main-content">
        <IndexHeader />
        <section className="py-6 pb-9 bg-default">
          <Container fluid>
            <Row className="justify-content-center text-center">
              <Col md="6">
                <h2 className="display-3 text-white title-text">
                  INVOICYの3つの特徴
                </h2>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg pt-lg-0 mt--7">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <Row>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <h4 className="h3">改正電子帳簿保存法に対応</h4>
                        <p className="description mt-3">
                          INVOICY(インボイシークラウド)は、2022年１月に改正された電子帳簿保存法に対応している為、作成した書類は全てクラウド上で管理することが可能です。
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <h4 className="h3">すべての機能が無料</h4>
                        <p className="description mt-3">
                          INVOICY(インボイシークラウド)は、完全無料で請求書・見積書の作成、さらにはフリーランスにとって嬉しい
                          業務報告書の作成ができます。
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <h4 className="h3">シンプルな操作でカンタン作成!</h4>
                        <p className="description mt-3">
                          使い方がシンプルで簡単に書類を作成することができます。
                          複製や書類の変更、作業報告書の一括登録など、シンプルな操作でカンタンに行えます。
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-6">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-md-2" md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/inputImg.png").default}
                />
              </Col>
              <Col className="order-md-1" md="6">
                <div className="pr-md-5">
                  <h1>
                    流れに沿って入力するだけで、
                    請求書や作業報告書をすばやく作成
                  </h1>
                  <p>
                    設定されている入力項目に沿って入力するだけで請求書や作業報告書がカンタンに作成できます。
                    消費税（内税・外税）など請求書作成に必要な税計算もあらかじめ設定されているので、
                    計算ミスを軽減することができます。
                    <br />
                    めんどくさい、作業報告書も一括登録！
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-6">
          <Container>
            <Row className="row-grid align-items-center">
              <Col md="6">
                <div className="d-flex">
                  <img
                    alt="作業報告書"
                    className={styles.docs}
                    src={require("assets/img/report.png").default}
                  />
                  <img
                    alt="職務経歴書"
                    className={styles.docs}
                    src={require("assets/img/invoice.png").default}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="pr-md-5">
                  <h1>見積書・請求書・作業報告書の管理が可能！</h1>
                  <p>
                    見積書から請求書への変換や毎月の作業報告書作成もカンタンです。転記ミスや記載漏れの心配はもうありません。PCブラウザはもちろんスマホやタブレットでも使えるので、場所や時間を選ばず請求業務が完了できます。
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-6">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-md-2" md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/convert.png").default}
                />
              </Col>
              <Col className="order-md-1" md="6">
                <div className="pr-md-5">
                  <h1>他の書類へワンクリックで変換</h1>
                  <p>
                    見積書から請求書など作成した書類は他の書類へ変換でき、経理業務の手間を大幅に効率化します。
                    また、書類の複製もワンクリックで完了
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-7 section-nucleo-icons bg-white overflow-hidden">
          <Container>
            <Row className="justify-content-center">
              <Col className="text-center" lg="8">
                <h2 className="display-3">提供している料金プラン</h2>
                <p className="lead">
                  INVOICY(インボイシークラウド)は、全ての機能を完全無料でお使いになれます。
                </p>
                <div className="btn-wrapper">
                  <Button
                    className="mt-3 mt-md-0 px-6"
                    color="default"
                    href="/register"
                  >
                    無料ではじめる
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-7">
          <div className="questionWrap">
            <Container>
              <Row>
                <Col className="mx-auto text-center" md="6">
                  <h2 className="display-3">よくある質問</h2>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto" md="12">
                  <div className="question" id="accordionExample">
                    <Card>
                      <CardHeader id="headingOne">
                        <h5 className="mb-0">
                          <Button
                            aria-expanded={openedCollapse === "collapse-1"}
                            onClick={() =>
                              setOpenedCollapse(
                                openedCollapse === "collapse-1"
                                  ? ""
                                  : "collapse-1"
                              )
                            }
                            className="w-100 text-primary text-left "
                            color="link"
                            type="button"
                          >
                            何の書類が作成できますか？
                            <i className="ni ni-bold-down float-right pt-1"></i>
                          </Button>
                        </h5>
                      </CardHeader>
                      <Collapse isOpen={openedCollapse === "collapse-1"}>
                        <CardBody className="opacity-8">
                          請求書と見積書、作業報告書が作成可能です。
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card>
                      <CardHeader id="headingTwo">
                        <h5 className="mb-0">
                          <Button
                            aria-expanded={openedCollapse === "collapse-2"}
                            onClick={() =>
                              setOpenedCollapse(
                                openedCollapse === "collapse-2"
                                  ? ""
                                  : "collapse-2"
                              )
                            }
                            className="w-100 text-primary text-left collapsed"
                            color="link"
                            type="button"
                          >
                            書類の保存期間はありますか？
                            <i className="ni ni-bold-down float-right pt-1"></i>
                          </Button>
                        </h5>
                      </CardHeader>
                      <Collapse isOpen={openedCollapse === "collapse-2"}>
                        <CardBody className="opacity-8">
                          保存期間の制限はございません。
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card>
                      <CardHeader id="headingThree">
                        <h5 className="mb-0">
                          <Button
                            aria-expanded={openedCollapse === "collapse-3"}
                            onClick={() =>
                              setOpenedCollapse(
                                openedCollapse === "collapse-3"
                                  ? ""
                                  : "collapse-3"
                              )
                            }
                            className="w-100 text-primary text-left collapsed"
                            color="link"
                            type="button"
                          >
                            利用料金はかかりますか？
                            <i className="ni ni-bold-down float-right pt-1"></i>
                          </Button>
                        </h5>
                      </CardHeader>
                      <Collapse isOpen={openedCollapse === "collapse-3"}>
                        <CardBody className="opacity-8">
                          現在、すべての機能を無料で提供しております。
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card>
                      <CardHeader id="headingFour">
                        <h5 className="mb-0">
                          <Button
                            aria-expanded={openedCollapse === "collapse-4"}
                            onClick={() =>
                              setOpenedCollapse(
                                openedCollapse === "collapse-4"
                                  ? ""
                                  : "collapse-4"
                              )
                            }
                            className="w-100 text-primary text-left"
                            color="link"
                            type="button"
                          >
                            ロゴや印鑑は設定できますか？
                            <i className="ni ni-bold-down float-right pt-1"></i>
                          </Button>
                        </h5>
                      </CardHeader>
                      <Collapse isOpen={openedCollapse === "collapse-4"}>
                        <CardBody className="opacity-8">
                          はい。追加することができます。
                        </CardBody>
                      </Collapse>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <section className="py-7 bg-white">
          <Container>
            <Row className="row-grid justify-content-center">
              <Col className="text-center" lg="8">
                <h2 className="display-3">
                  まずは無料で請求書を作成してみませんか？
                </h2>

                <div className="btn-wrapper mt-5">
                  <Button className="my-2" color="default" href="/login">
                    無料でつかってみる
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <IndexFooter />
    </>
  );
}

export default Index;
