import React, { useState, useEffect, useLayoutEffect, useContext } from "react";

import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Helmet } from "react-helmet";
import AuthHeader from "components/Headers/AuthHeader.js";
import Auth from "layouts/Auth";
import {
  login,
  signupWithGoogle,
  signinWithGithub,
  useAuth,
} from "../../../firebase";
import { AuthContext } from "../../../providers/AuthProvider";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
function Login() {
  const history = useHistory();
  const context = useContext(AuthContext);
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const handleLogin = async (email, password) => {
    try {
      const user = await login(email, password);

      if (user) {
        history.push("/invoices");
      }
      setIsError(false);
    } catch (error) {
      setIsError(true);
    }
  };
  const handleSignInWithGoogle = () => {
    try {
      signupWithGoogle();
      setIsError(false);
    } catch (error) {
      setIsError(true);
    }
  };

  const handleSignInWithGitHub = () => {
    try {
      signinWithGithub();
      setIsError(false);
    } catch (error) {
      setIsError(true);
    }
  };

  const onSubmit = async (data) => {
    await handleLogin(data.email, data.password);
  };

  useEffect(() => {
    if (context.userInfo) {
      history.push("/invoices");
    }
    if (!context.authLoading) {
      setLoading(false);
    }
  }, [context.authLoading, context.userInfo]);

  return (
    <>
      <Helmet>
        <title>INVOICY ログイン</title>
        <meta
          property="og:description"
          content="完全無料
フリーランス向け
請求書、作業報告書管理ツール INVOICY(インボイシークラウド)　| ログイン"
        />
        <meta
          name="description"
          content="完全無料
フリーランス向け
請求書、作業報告書管理ツール INVOICY(インボイシークラウド)　| ログイン"
        />
      </Helmet>
      <Auth>
        <AuthHeader title="" lead="" />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-center mt-2 mb-3 font-weight-bold">
                    <h1>
                      <small>インボイシーIDでログイン</small>
                    </h1>

                    <small>
                      サービス利用にはインボイシーIDが必要です。
                      <br />
                      下記サービスもしくは、
                      <br />
                      ご登録のメール&パスワードでログインしてください。
                    </small>
                    {isError && (
                      <p className="text-red">
                        ログインに失敗しました。
                        <br />
                        メール、パスワードをご確認の上再度お試しください。
                      </p>
                    )}
                  </div>
                  <div className="btn-wrapper text-center">
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      onClick={handleSignInWithGitHub}
                    >
                      <span className="btn-inner--icon mr-1">
                        <img
                          src={
                            require("assets/img/icons/common/github.svg")
                              .default
                          }
                        />
                      </span>
                      <span className="btn-inner--text">Github</span>
                    </Button>
                    {/* <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      onClick={handleSignInWithGoogle}
                    >
                      <span className="btn-inner--icon mr-1">
                        <img
                          alt="..."
                          src={
                            require("assets/img/icons/common/google.svg")
                              .default
                          }
                        />
                      </span>
                      <span className="btn-inner--text">Google</span>
                    </Button> */}
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          {...register("email", {
                            required: {
                              value: true,
                              message: "メールは必須項目です",
                            },
                          })}
                          className={`form-control ${
                            errors?.email?.message ? "border-danger" : ""
                          }`}
                          name="email"
                          placeholder="メール"
                          type="email"
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(false)}
                        />
                      </InputGroup>
                      {errors?.email?.message && (
                        <span className="text-red">{errors.email.message}</span>
                      )}
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: focusedPassword,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          {...register("password", {
                            required: {
                              value: true,
                              message: "パスワードは必須項目です。",
                            },
                            minLength: {
                              value: 8,
                              message:
                                "パスワードは8文字以上で入力してください",
                            },
                          })}
                          className={`form-control ${
                            errors?.password?.message ? "border-danger" : ""
                          }`}
                          name="password"
                          type="password"
                          placeholder="パスワード"
                          onFocus={() => setfocusedPassword(true)}
                          onBlur={() => setfocusedPassword(false)}
                        />
                      </InputGroup>
                      {errors?.password?.message && (
                        <span className="text-red">
                          {errors.password.message}
                        </span>
                      )}
                    </FormGroup>

                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="default"
                        type="submit"
                        // onClick={handleLogin}
                      >
                        ログインする
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <Link className="text-light cursor-pointer" to="/password">
                    パスワードを忘れた方はこちら
                  </Link>
                </Col>
                <Col className="text-right" xs="6">
                  <Link className="text-light cursor-pointer" to="/register">
                    新規登録はこちら
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Auth>
    </>
  );
}

export default Login;
