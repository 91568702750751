import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./Dropzone.module.scss";
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: "auto",
  maxWidth: "100%",
  objectFit: "contain",
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
  marginTop: "24px",
};

const deleteIcon = {
  position: "absolute",
  width: "28px",
  height: "28px",
  background: "white",
  top: "-30px",
  cursor: "pointer",
};
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
  objectFit: "contain",
  maxWidth: "100%",
};

const Dropzone = (props) => {
  const [files, setFiles] = useState([]);
  const [defaultImage, setDefaultImage] = useState();
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      props?.setData(acceptedFiles.map((file) => file)[0]);
    },
  });

  const onDeleteFile = () => {
    setFiles([]);
    props?.setData(null);
    setDefaultImage();
  };
  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={deleteIcon} onClick={() => onDeleteFile()}>
        <i class="fas fa-trash-alt"></i>
      </div>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  const previewDefaultImage = () => (
    <div style={thumb}>
      <div style={thumbInner}>
        <img src={defaultImage} style={img} />
      </div>
    </div>
  );

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    if (props.defaultValue) {
      setDefaultImage(props.defaultValue);
    }
  }, [props?.defaultValue]);

  return (
    <section className={styles.dropzoneWrap}>
      <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} />
        <span>ドラッグ＆ドロップかファイルを選択してください</span>
      </div>
      <aside style={thumbsContainer}>
        {!files.length && defaultImage ? (
          <div style={thumb}>
            <div style={deleteIcon} onClick={() => onDeleteFile()}>
              <i class="fas fa-trash-alt"></i>
            </div>

            <div style={thumbInner}>
              <img src={defaultImage} style={img} />
            </div>
          </div>
        ) : (
          ""
        )}
        {thumbs}
      </aside>
    </section>
  );
};

export default Dropzone;
