import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

import styles from "./ReportFormModal.module.scss";
import { db, useAuth, storageRef } from "../../firebase";

import dayjs from "dayjs";

const ReportFormModal = ({ modal, setModal, handleApplyInput }) => {
  const days = ["日", "月", "火", "水", "木", "金", "土"];
  const [start, setStart] = useState("00:00");
  const [end, setEnd] = useState("00:00");
  const [breakTime, setBreakTime] = useState("00:00");
  const [activeDays, setActiveDays] = useState([]);
  const [note, setNote] = useState("");
  const toggle = () => setModal(!modal);

  const onChangeCheckBox = (d) => {
    if (activeDays.includes(d)) {
      let newArr = [...activeDays];
      const index = newArr.indexOf(d);
      newArr.splice(index, 1);
      setActiveDays([...newArr]);
    } else {
      setActiveDays([...activeDays, d]);
    }
  };

  const onChangeInput = (title, value) => {
    if (title === "start") {
      setStart(value);
    }
    if (title === "end") {
      setEnd(value);
    }
    if (title === "note") {
      setNote(value);
    }
    if (title === "breakTime") {
      setBreakTime(value);
    }
  };

  const handleReset = () => {
    setStart("00:00");
    setEnd("00:00");
    setBreakTime("00:00");
    setNote("");
    setActiveDays([]);
  };

  const handleSubmit = () => {
    handleApplyInput({
      activeDays,
      start,
      end,
      breakTime,
      note,
    });
    toggle();
  };

  return (
    <div>
      <Modal className={styles.modal} isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>一括編集</ModalHeader>
        <ModalBody>
          <FormGroup>
            <label className="form-control-label" htmlFor="title">
              曜日選択
            </label>
            <div className="d-flex flex-wrap">
              {days?.map((d, i) => (
                <div
                  key={i}
                  className="custom-control custom-checkbox custom-checkbox-primary mr-3 mb-2"
                >
                  <input
                    className="custom-control-input"
                    id={`check-${d + i}`}
                    type="checkbox"
                    checked={activeDays.includes(d)}
                    onChange={() => onChangeCheckBox(d)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`check-${d + i}`}
                  >
                    {d}
                  </label>
                </div>
              ))}
            </div>
          </FormGroup>
          <Row>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="title">
                  開始
                </label>
                <input
                  className="form-control"
                  id="title"
                  placeholder=""
                  type="time"
                  value={start}
                  onChange={(e) => onChangeInput("start", e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="title">
                  終了
                </label>
                <input
                  className="form-control"
                  id="title"
                  placeholder=""
                  type="time"
                  value={end}
                  onChange={(e) => onChangeInput("end", e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="title">
                  休憩
                </label>
                <input
                  className="form-control"
                  id="title"
                  placeholder=""
                  type="time"
                  value={breakTime}
                  onChange={(e) => onChangeInput("breakTime", e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="title">
                  作業内容
                </label>
                <textarea
                  className="form-control"
                  id="title"
                  placeholder=""
                  type="textarea"
                  value={note}
                  onChange={(e) => onChangeInput("note", e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col sm="12">
              <Button color="secondary" size="sm" onClick={() => handleReset()}>
                リセット
              </Button>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggle()}>
            閉じる
          </Button>
          <Button color="primary" onClick={() => handleSubmit()}>
            適用
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ReportFormModal;
