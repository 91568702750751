import React, { useEffect, useState, memo } from "react";
import compose from "../../styles/compose";
import { chunkArrayInGroups, formatDate } from "utils/common";

import {
  Text,
  View,
  PDFDownloadLink,
  Page,
  Document,
  Font,
  Image,
} from "@react-pdf/renderer";
import { getDownloadURL } from "firebase/storage";
import { db, storageRef, useAuth } from "../../firebase";

import fontRegular from "../../fonts/Nasu-Regular.ttf";
import fontNunitoRegular from "../../fonts/Nunito-Regular.ttf";
import fontNunitoBold from "../../fonts/Nunito-Bold.ttf";
import fontNotoSansJP from "../../fonts/NotoSansJP-Regular.otf";
import fontRoboto from "../../fonts/roboto-black-webfont.ttf";
Font.register({
  family: "Nunito",
  fonts: [
    { src: fontNunitoRegular },
    {
      src: fontNunitoBold,
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: "Nasu-Regular",
  src: fontRegular,
});

Font.register({
  family: "NotoSansJP",
  fonts: [
    {
      src: fontNotoSansJP,
    },
    {
      src: fontRoboto,
    },
  ],
});

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: fontRoboto,
    },
  ],
});

const styles = {
  "th-title-first": {
    breakInside: "avoid",
    width: "80px",
    textAlign: "left",
    backgroundColor: "#d8d8d8",
    // flexWrap: "wrap",
    padding: "3px 4px",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    fontSize: "9px",
  },
  "td-title-first": {
    breakInside: "avoid",
    width: "193px",
    textAlign: "left",
    padding: "3px 4px",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    fontSize: "9px",
  },
  "th-title": {
    breakInside: "avoid",
    width: "80px",
    textAlign: "left",
    backgroundColor: "#d8d8d8",
    // flexWrap: "wrap",
    padding: "2px 4px",
    borderLeft: "1px solid black",
    // borderTop: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    fontSize: "9px",
  },
  "td-title": {
    breakInside: "avoid",
    width: "193px",
    textAlign: "left",
    padding: "2px 4px",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    fontSize: "9px",
    position: "relative",
  },
  title: {
    fontSize: "24px",
    textAlign: "center",
    width: "100%",
    display: "block",
  },
  date: {
    breakInside: "avoid",
    width: "77px",
    padding: "1px 8px 0",
    fontSize: "9px",
    minHeight: "15px",
    textAlign: "left",
    borderRight: "1px solid black",
    color: "black",
  },
  time: {
    breakInside: "avoid",
    width: "55px",
    padding: "1px 2px 0",
    fontSize: "9px",
    textAlign: "center",
    minHeight: "15px",
    borderRight: "1px solid black",
  },
  note: {
    breakInside: "avoid",
    width: "246px",
    padding: "1px 8px 0px",
    fontSize: "9px",
    minHeight: "15px",
  },
  noteWrap: {
    width: "545px",
    fontSize: "9px",
    backgroundColor: "#d8d8d8",
    padding: "2px 4px",
    border: "1px solid black",
    minHeight: "48px",
  },
  signature: {
    top: "-20px",
    height: "56px",
    width: "56px",
    display: "block",
    "object-fit": "contain",
    position: "absolute",
    right: "0",
  },
};
const Report = ({ data, title }) => {
  const [signaturePreSignedUrl, setSignaturePreSignedUrl] = useState();
  const getPresignedUrl = async (path) => {
    const tmp = await storageRef(path);
    const pre = await getDownloadURL(tmp);

    return pre;
  };
  useEffect(async () => {
    if (data?.signatureUrl) {
      const signaturePresignedUrl = await getPresignedUrl(data.signatureUrl);
      setSignaturePreSignedUrl(signaturePresignedUrl);
    }
  }, [data?.signatureUrl]);

  return (
    <Document pdfMode={true} renderMode="canvas">
      <Page size="A4" style={compose("page invoice-wrapper")} pdfMode={true}>
        <Text style={styles.title}>{title}</Text>
        <View
          style={{
            breakInside: "avoid",
            display: "flex",
            flexWrap: "wrap",
            marginRight: "-15px",
            marginLeft: "-15px",
            flexDirection: "row",
            marginTop: "10px",
          }}
        >
          <View
            style={{
              breakInside: "avoid",
              width: "287px",
            }}
          >
            <View
              style={{
                breakInside: "avoid",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <View
                style={{
                  breakInside: "avoid",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                {/* <Text
                  style={{
                    fontSize: "12px",
                    marginLeft: "15px",
                    marginBottom: "2.5px",
                  }}
                >
                  {data?.toName ? `企業名: ${data?.toName}` : ""}
                </Text> */}
              </View>
            </View>

            <View
              style={{
                breakInside: "avoid",
                width: "250px",
                marginLeft: "15px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                backgroundColor: "#d8d8d8",
                marginBottom: "2.5px",
              }}
            >
              {/* <Text
                style={{
                  fontSize: "12px",
                  padding: "4px 8px",
                }}
              >
                {data?.title ? `件名: ${data?.title}` : ""}
              </Text> */}
            </View>
          </View>
          <View
            style={{
              breakInside: "avoid",
              width: "273px",
            }}
          >
            <View
              style={{
                breakInside: "avoid",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <View style={styles["th-title-first"]}>
                <Text style={compose("f-9")}>稼働年月</Text>
              </View>
              <View style={styles["td-title-first"]}>
                <Text style={compose("f-9")}>
                  {data?.displayReportMonth ? `${data.displayReportMonth}` : ""}
                </Text>
              </View>
            </View>
            <View
              style={{
                breakInside: "avoid",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <View style={styles["th-title"]}>
                <Text style={compose("f-9")}>参画先企業名</Text>
              </View>
              <View style={styles["td-title"]}>
                <Text style={compose("f-9")}>
                  {data?.toName ? `${data.toName}` : ""}
                </Text>
              </View>
            </View>
            <View
              style={{
                breakInside: "avoid",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <View style={styles["th-title"]}>
                <Text style={compose("f-9")}>稼働者</Text>
              </View>
              <View style={styles["td-title"]}>
                <Text style={compose("f-9")}>
                  {data?.fromName ? `${data.fromName}` : ""}
                </Text>
                {data?.signatureUrl && signaturePreSignedUrl ? (
                  <>
                    <Image
                      style={styles.signature}
                      src={signaturePreSignedUrl}
                    />

                    <img style={styles.signature} src={signaturePreSignedUrl} />
                  </>
                ) : (
                  <></>
                )}
              </View>
            </View>
            {/* <View style={compose("view flex w-100")}>
              <View style={compose("view flex w-100 mb-2.5")}>
                <Text style={compose("f-12 block")}>
                  {data?.tel ? `TEL：${data.tel}` : ""}
                </Text>
              </View>
              <View style={compose("view flex w-100 mb-2.5")}>
                <Text style={compose("f-12 block")}>
                  {data?.mail ? `Email：${data.mail}` : ""}
                </Text>
              </View>
            </View> */}
          </View>
        </View>
        <View style={compose("view flex mt-20 ")}>
          <View style={compose("view tableHead")}>
            <View style={compose("view flex bb bl br bt")} wrap={false}>
              <View
                style={{
                  breakInside: "avoid",
                  width: "77px",
                  borderRight: "1px solid black",
                  backgroundColor: "#d8d8d8",
                  textAlign: "center",
                  color: "black",
                  fontWeight: "bold",
                  padding: "3px 4px",
                  fontSize: "9px",
                }}
              >
                <Text>日付</Text>
              </View>
              <View
                style={{
                  breakInside: "avoid",
                  width: "55px",
                  borderRight: "1px solid black",
                  backgroundColor: "#d8d8d8",
                  textAlign: "center",
                  color: "black",
                  fontWeight: "bold",
                  padding: "3px 4px",
                  fontSize: "9px",
                }}
              >
                <Text>開始時刻</Text>
              </View>
              <View
                style={{
                  breakInside: "avoid",
                  width: "55px",
                  borderRight: "1px solid black",
                  backgroundColor: "#d8d8d8",
                  textAlign: "center",
                  color: "black",
                  fontWeight: "bold",
                  padding: "3px 4px",
                  fontSize: "9px",
                }}
              >
                <Text>終了時刻</Text>
              </View>
              <View
                style={{
                  breakInside: "avoid",
                  width: "55px",
                  borderRight: "1px solid black",
                  backgroundColor: "#d8d8d8",
                  textAlign: "center",
                  color: "black",
                  fontWeight: "bold",
                  padding: "3px 4px",
                  fontSize: "9px",
                }}
              >
                <Text>休憩時間</Text>
              </View>
              <View
                style={{
                  breakInside: "avoid",
                  width: "55px",
                  borderRight: "1px solid black",
                  backgroundColor: "#d8d8d8",
                  textAlign: "center",
                  color: "black",
                  fontWeight: "bold",
                  padding: "3px 4px",
                  fontSize: "9px",
                }}
              >
                <Text>作業時間</Text>
              </View>
              <View
                style={{
                  breakInside: "avoid",
                  backgroundColor: "#d8d8d8",
                  textAlign: "center",
                  color: "black",
                  fontWeight: "bold",
                  padding: "3px 4px",
                  fontSize: "9px",
                  flexGrow: 1,
                }}
              >
                <Text>作業内容</Text>
              </View>
            </View>
          </View>

          <View
            style={{
              breakInside: "avoid",
              width: "545px",
            }}
          >
            {data.items.length > 0 &&
              data?.items?.map((item, index) => {
                return (
                  <View
                    style={{
                      breakInside: "avoid",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                      marginTop: "-1px",
                    }}
                    wrap={false}
                  >
                    <View style={styles.date}>
                      {/* <View style={compose("view w-50 td left br")}>
                        <Text style={compose("f-11")}>{item?.title}</Text>
                      </View> */}
                      <Text style={compose("f-9")}>{item.date}</Text>
                    </View>
                    <View style={styles.time}>
                      <Text style={compose("f-9 center")}>{item?.start}</Text>
                    </View>
                    <View style={styles.time}>
                      <Text style={compose("f-9")}>{item?.end}</Text>
                    </View>
                    <View style={styles.time}>
                      <Text style={compose("f-9")}>{item?.break}</Text>
                    </View>
                    <View style={styles.time}>
                      <Text style={compose("f-9")}>{item?.dailyTotal}</Text>
                    </View>
                    <View style={styles.note}>
                      <Text style={compose("f-9")}>{item?.note}</Text>
                    </View>
                    {/* <Text
                      style={{
                        breakInside: "avoid",
                        padding: "4px 8px",
                        fontSize: "10px",
                        height: "20px",
                        textAlign: "right",
                      }}
                    >
                      {item?.dailyTotal}
                    </Text> */}
                  </View>
                );
              })}
            <View
              style={{
                breakInside: "avoid",
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#d8d8d8",
                flexWrap: "wrap",
                borderLeft: "1px solid black",
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                marginTop: "-1px",
              }}
              wrap={false}
            >
              <View style={styles.date}>
                {/* <View style={compose("view w-50 td left br")}>
                        <Text style={compose("f-11")}>{item?.title}</Text>
                      </View> */}
                <Text style={compose("f-9")}>合計</Text>
              </View>
              <View style={styles.time}>
                <Text style={compose("f-9 center")}>作業日数</Text>
              </View>
              <View style={styles.time}>
                <Text style={compose("f-9")}>{data.totalWorkingDays}</Text>
              </View>
              <View style={styles.time}>
                <Text style={compose("f-9")}>作業時間</Text>
              </View>
              <View style={styles.time}>
                <Text style={compose("f-9")}>{data.totalWorkingHours}</Text>
              </View>
              <View style={styles.note}>
                <Text style={compose("f-9")}></Text>
              </View>
              {/* <Text
                      style={{
                        breakInside: "avoid",
                        padding: "4px 8px",
                        fontSize: "10px",
                        height: "20px",
                        textAlign: "right",
                      }}
                    >
                      {item?.dailyTotal}
                    </Text> */}
            </View>
          </View>
        </View>
        <View style={compose("view flex mt-20")}>
          <View style={compose("view f-9")}>
            <Text style={compose("f-9")}>備考</Text>
            <View style={compose("view flex")}>
              <View style={styles.noteWrap}>
                <Text style={compose("f-9")}>
                  {data?.note ? data?.note : ""}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Report;
