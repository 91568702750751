import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Container,
  Row,
} from "reactstrap";
import AdminLayout from "layouts/Admin.js";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styles from "./EstimateEdit.module.scss";
import InvoiceForm from "components/InvoiceForm/InvoiceForm";
import { db } from "../../../firebase";
import { AuthContext } from "../../../providers/AuthProvider";
import {
  collection,
  getDocs,
  setDoc,
  doc,
  where,
  query,
} from "firebase/firestore";
import { uploadImage, deleteImage } from "../../../utils/common";
import { IMAGE_TYPE } from "../../../utils/const";

const EstimateEdit = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const context = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState();
  const [subTotal, setSubTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [initialData, setInitialData] = useState();
  const [estimateId, setInvoiceId] = useState();
  const [items, setItems] = useState([]);
  const [logoFile, setLogoFile] = useState(null);
  const [signatureFile, setSignatureFile] = useState(null);
  const onSubmit = async (d) => {
    if (initialData?.logoUrl && logoFile !== initialData?.logoUrl) {
      await deleteImage(initialData.logoUrl);
    }

    if (
      initialData?.signatureUrl &&
      signatureFile !== initialData?.signatureUrl
    ) {
      await deleteImage(initialData.signatureUrl);
    }
    if (logoFile) {
      if (logoFile !== initialData?.logoUrl) {
        d.logoUrl = await uploadImage(IMAGE_TYPE.LOGO, id, logoFile);
        // if (initialData?.logoUrl && !initialData?.copy) {
        //   await deleteImage(initialData.logoUrl);
        // }
      }
    } else {
      d.logoUrl = "";
    }

    if (signatureFile) {
      if (signatureFile !== initialData?.signatureUrl) {
        d.signatureUrl = await uploadImage(
          IMAGE_TYPE.SIGNATURE,
          id,
          signatureFile
        );
      }
      // if (initialData?.signatureUrl && !initialData?.copy) {
      //   await deleteImage(initialData.signatureUrl);
      // }
    } else {
      d.signatureUrl = "";
    }

    // if (initialData?.logoUrl && !initialData?.copy) {
    //   await deleteImage(initialData.logoUrl);
    // }
    // if (initialData?.signatureUrl && !initialData?.copy) {
    //   await deleteImage(initialData.signatureUrl);
    // }

    const emp = {
      ...initialData,
      ...d,
      items: items,
      id,
      subTotal,
      taxTotal,
      total,
    };

    try {
      const estimatesCollectionRef = collection(
        db,
        "docs",
        currentUser.uid,
        "estimates"
      );

      await setDoc(doc(estimatesCollectionRef, estimateId), emp)
        .then((d) =>
          history.push(`/estimates/${id}/review`, { estimateData: emp })
        )
        .catch((e) => console.error(e));
    } catch (error) {
      console.log(error);
    }
  };
  const fetchInvoiceData = async () => {
    try {
      const estimatesCollectionRef = collection(
        db,
        "docs",
        currentUser.uid,
        "estimates"
      );
      const q = query(estimatesCollectionRef, where("id", "==", id));

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.docs.length) {
        history.push("/error/404");
      }
      querySnapshot.forEach((doc) => {
        const d = doc.data();
        setInvoiceId(doc.id);
        setInitialData(d);
        setLogoFile(d?.logoUrl);
        setSignatureFile(d?.signatureUrl);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let tmpSubTotal = 0;
    let tmpTaxTotal = 0;

    if (initialData?.items) {
      setItems([...initialData.items]);
      initialData?.items.forEach((item) => {
        tmpSubTotal += item.unitPrice * item.amount;
        tmpTaxTotal += Math.floor(
          item.unitPrice * item.amount * (item.taxRate / 100)
        );
      });
    }

    setTaxTotal(tmpTaxTotal);
    setSubTotal(tmpSubTotal);
    setTotal(tmpTaxTotal + tmpSubTotal);
  }, [initialData]);

  useEffect(() => {
    let tmpSubTotal = 0;
    let tmpTaxTotal = 0;

    items.forEach((item) => {
      tmpSubTotal += item.unitPrice * item.amount;
      tmpTaxTotal += Math.floor(
        item.unitPrice * item.amount * (item.taxRate / 100)
      );
    });

    setTaxTotal(tmpTaxTotal);
    setSubTotal(tmpSubTotal);
    setTotal(tmpTaxTotal + tmpSubTotal);
  }, [items]);

  useEffect(() => {
    if (currentUser) {
      if (location?.state?.estimateData) {
        setInitialData(location.state.estimateData);
        setLoading(false);
      } else {
        fetchInvoiceData();
        setLoading(false);
      }
    }
  }, [currentUser]);

  //ログイン情報格納処理
  useEffect(() => {
    if (context?.userInfo?.uid) {
      setCurrentUser(context.userInfo);
    }
  }, [context]);

  return (
    <AdminLayout>
      {loading ? (
        <></>
      ) : (
        <Container className="mt-6 mt-xl-2" fluid>
          <Row>
            <div className="col">
              {currentUser && initialData && (
                <Card>
                  <Form>
                    <CardHeader className="border-0">
                      <h3 className="mb-0">請求書</h3>
                    </CardHeader>
                    <CardBody>
                      <InvoiceForm
                        type="estimate"
                        estimateNumber={initialData?.estimateNumber}
                        initialData={initialData}
                        register={register}
                        errors={errors}
                        subTotal={subTotal}
                        taxTotal={taxTotal}
                        total={total}
                        items={items}
                        setItems={setItems}
                        setLogoFile={setLogoFile}
                        setSignatureFile={setSignatureFile}
                      />
                    </CardBody>
                    <CardFooter className={styles.stickyFooter}>
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                      >
                        <i class="fas fa-save"></i>
                        <span className="btn-inner--text">保存する</span>
                      </Button>
                    </CardFooter>
                  </Form>
                </Card>
              )}
            </div>
          </Row>
        </Container>
      )}
    </AdminLayout>
  );
};

export default EstimateEdit;
