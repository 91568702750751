import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Container,
  Row,
} from "reactstrap";
import AdminLayout from "layouts/Admin.js";
import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
import { useForm } from "react-hook-form";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styles from "./WorkReportEdit.module.scss";
import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  where,
  query,
} from "firebase/firestore";
import ReportForm from "components/ReportForm/ReportForm";
import { AuthContext } from "../../../providers/AuthProvider";
import { IMAGE_TYPE } from "../../../utils/const";
import { uploadImage, deleteImage } from "../../../utils/common";
dayjs.locale(ja);
const WorkReportEdit = () => {
  const context = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [reportId, setReportId] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [signatureFile, setSignatureFile] = useState(null);
  const [initialData, setInitialData] = useState({});
  const [reportMonth, setReportMonth] = useState();
  const [items, setItems] = useState([]);
  const [totalWorkingDays, setTotalWorkingDays] = useState(0);
  const [totalWorkingHours, setTotalWorkingHours] = useState(0);
  const [data, setData] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (
      initialData?.signatureUrl &&
      signatureFile !== initialData?.signatureUrl
    ) {
      await deleteImage(initialData.signatureUrl);
    }

    if (signatureFile) {
      if (signatureFile !== initialData?.signatureUrl) {
        data.signatureUrl = await uploadImage(
          IMAGE_TYPE.SIGNATURE,
          id,
          signatureFile
        );
      }
    } else {
      data.signatureUrl = "";
    }

    const emp = {
      ...initialData,
      ...data,
      items: items,
      id,
      reportMonth,
      displayReportMonth: dayjs(reportMonth).format("YYYY年MM月"),
      totalWorkingDays,
      totalWorkingHours,
    };

    try {
      const reportsCollectionRef = collection(
        db,
        "docs",
        currentUser.uid,
        "reports"
      );
      await setDoc(doc(reportsCollectionRef, reportId), emp)
        .then((d) =>
          history.push(`/reports/${id}/review`, { invoiceData: emp })
        )
        .catch((e) => console.error(e));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReportData = async () => {
    const reportsCollectionRef = collection(
      db,
      "docs",
      currentUser.uid,
      "reports"
    );
    const q = query(reportsCollectionRef, where("id", "==", id));

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.docs.length) {
      history.push("/error/404");
    }
    querySnapshot.forEach(async (doc) => {
      const d = doc.data();
      setReportId(doc.id);
      setReportMonth(d.reportMonth);
      setInitialData(d);
      setSignatureFile(d?.signatureUrl);
    });
  };

  useEffect(() => {
    let tDays = 0;
    let tHours = 0;
    let tMins = 0;
    items.forEach((item) => {
      if (item?.totalHours > 0 || item?.totalMins > 0) {
        tDays++;
      }
      if (item?.totalHours) {
        tHours = tHours + item.totalHours;
      }
      if (item?.totalMins) {
        tMins = tMins + item.totalMins;
      }
    });

    tHours = tHours + Math.floor(tMins / 60);

    const amari = tMins % 60;
    const displayHM = `${tHours}:${("00" + Math.abs(amari)).slice(-2)}`;
    setTotalWorkingDays(tDays);
    setTotalWorkingHours(displayHM);
  }, [items]);

  useEffect(() => {
    if (initialData.items) {
      setItems([...initialData.items]);
    }
  }, [initialData]);

  useEffect(() => {
    const thisMonth = dayjs(reportMonth).startOf("month").format("YYYY-MM");
    const nextMonthStart = dayjs(reportMonth)
      .add(1, "month")
      .format("YYYY-MM-DD");
    const thisMonthStart = dayjs(reportMonth)
      .startOf("month")
      .format("YYYY-MM-DD");

    const diff = dayjs(nextMonthStart).diff(thisMonthStart, "day");
    const days = [];
    [...Array(diff)].map((_, i) => {
      const date = dayjs(`${thisMonth}-${i + 1}`).format("MMMD日(dd)");
      const day = dayjs(`${thisMonth}-${i + 1}`).format("dd");
      days.push({
        id: i,
        date: date,
        day: day,
        start: "00:00",
        end: "00:00",
        break: "00:00",
        dailyTotal: "00:00",
        note: "",
      });
    });
    setItems(days);
  }, [reportMonth]);

  //ログイン情報格納処理
  useEffect(() => {
    if (!context?.userInfo?.uid) {
      return;
    }
    setCurrentUser(context.userInfo);
  }, [context]);

  useEffect(() => {
    if (currentUser) {
      if (location?.state?.reportData) {
        setInitialData(location.state.reportData);
      } else {
        fetchReportData();
      }
      setLoading(false);
    }
  }, [currentUser]);

  return (
    <AdminLayout>
      {loading ? (
        <></>
      ) : (
        <Container className="mt-6 mt-xl-2" fluid>
          <Row>
            <div className="col">
              {currentUser && initialData?.toName ? (
                <Card>
                  <Form>
                    <CardHeader className="border-0">
                      <h3 className="mb-0">作業報告書</h3>
                    </CardHeader>
                    <CardBody>
                      <ReportForm
                        type="report"
                        reportNumber={initialData?.reportNumber}
                        initialData={initialData}
                        register={register}
                        errors={errors}
                        items={items}
                        reportMonth={reportMonth}
                        setItems={setItems}
                        setReportMonth={setReportMonth}
                        setSignatureFile={setSignatureFile}
                        totalWorkingHours={totalWorkingHours}
                        totalWorkingDays={totalWorkingDays}
                      />
                    </CardBody>
                    <CardFooter className={styles.stickyFooter}>
                      <Button
                        className="btn-icon"
                        color="default"
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                      >
                        <i class="fas fa-save"></i>
                        <span className="">保存する</span>
                      </Button>
                    </CardFooter>
                  </Form>
                </Card>
              ) : (
                <></>
              )}
            </div>
          </Row>
        </Container>
      )}
    </AdminLayout>
  );
};

export default WorkReportEdit;
