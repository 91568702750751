import { useLayoutEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  GithubAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";
import { getFirestore } from "@firebase/firestore";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_SENDER_ID,
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth();
auth.languageCode = "ja";
export const db = getFirestore(app);

export const storage = getStorage(app);
export const logoRef = (id, file) =>
  ref(storage, id + "/logo/" + "logo-" + file?.name);
export const storageRef = (path) => ref(storage, path);
export const signatureRef = (id, file) =>
  ref(storage, id + "/signature/" + "signature-" + file?.name);
export function signup(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export const signupWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
  provider.setCustomParameters({
    login_hint: "user@example.com",
  });
  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log("error");
      // ...
    });
  // ポップアップウィンドウでログインを行う場合はsignInWithPopupを呼び出す
};

export const resetPassword = (actionCode, password) => {
  return verifyPasswordResetCode(auth, actionCode).then((email) => {
    const accountEmail = email;

    const newPassword = password;

    // Save the new password.
    return confirmPasswordReset(auth, actionCode, newPassword)
      .then((resp) => {
        return auth.signInWithEmailAndPassword(accountEmail, newPassword);
      })
      .catch((error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      });
  });
};
export const signinWithGithub = () => {
  const provider = new GithubAuthProvider();
  provider.addScope("repo");
  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a GitHub Access Token. You can use it to access the GitHub API.
      const credential = GithubAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      // The signed-in user info.
      const user = result.user;

      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GithubAuthProvider.credentialFromError(error);
      console.log("error", errorMessage);
      // ...
    });
  // ポップアップウィンドウでログインを行う場合はsignInWithPopupを呼び出す
};

export const sendPasswordResetLink = (email) => {
  return sendPasswordResetEmail(auth, email)
    .then(() => {
      return true;
    })
    .catch((error) => {
      return false;
    });
};

export function login(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function logout() {
  return signOut(auth);
}

// Custom Hook
export function useAuth() {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useLayoutEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setIsLoading(false);
    });
    return unsub;
  }, []);

  return { currentUser, isLoading };
}
