import React, { useEffect, useLayoutEffect, useContext } from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { useAuth, auth } from "../firebase";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import { useTracking } from "../hooks/useTracking";
function Bare({ children }) {
  useTracking("G-PP2TVZX77J");
  const history = useHistory();
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const context = useContext(AuthContext);
  const mainContentRef = React.useRef(null);

  // const getBrandText = (path) => {
  //   for (let i = 0; i < routes.length; i++) {
  //     if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
  //       return routes[i].name;
  //     }
  //   }
  //   return "Brand";
  // };
  // toggles collapse between mini sidenav and normal
  // const toggleSidenav = (e) => {
  //   if (document.body.classList.contains("g-sidenav-pinned")) {
  //     document.body.classList.remove("g-sidenav-pinned");
  //     document.body.classList.add("g-sidenav-hidden");
  //   } else {
  //     document.body.classList.add("g-sidenav-pinned");
  //     document.body.classList.remove("g-sidenav-hidden");
  //   }
  //   setSidenavOpen(!sidenavOpen);
  // };
  // const getNavbarTheme = () => {
  //   return location.pathname.indexOf("admin/alternative-dashboard") === -1
  //     ? "dark"
  //     : "light";
  // };

  // useLayoutEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   mainContentRef.current.scrollTop = 0;
  // }, [location]);

  //ページ読み込み直後にユーザー情報が取得できないため取得後にログイン済みか判断
  useLayoutEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        history.push("/login");
      }

      context.setUserInfo(user);
    });
  }, []);

  return <div className="h-100vh">{children}</div>;
}

export default Bare;
