import React, { useState, useEffect, useContext } from "react";

import BareLayout from "layouts/Bare.js";

import Invoice from "../../../components/Pdf/Invoice";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { db, storageRef } from "../../../firebase";
import { collection, getDocs, where, query } from "firebase/firestore";
import { AuthContext } from "../../../providers/AuthProvider";
import { PDFViewer } from "@react-pdf/renderer";

const EstimateShow = () => {
  const { id } = useParams();
  const location = useLocation();

  const [data, setData] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const context = useContext(AuthContext);
  //ログイン情報格納処理
  useEffect(() => {
    if (!context?.userInfo?.uid) {
      return <></>;
    }
    setCurrentUser(context.userInfo);
  }, [context]);

  const fetchInvoiceData = async () => {
    try {
      const estimatesCollectionRef = collection(
        db,
        "docs",
        currentUser.uid,
        "estimates"
      );
      const q = query(estimatesCollectionRef, where("id", "==", id));

      const querySnapshot = await getDocs(q);
      let d;
      querySnapshot.forEach((doc) => {
        d = doc.data();
        setData(d);
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  // useEffect(() => {
  //   let tmpSubTotal = 0;
  //   let tmpTaxTotal = 0;

  //   items?.forEach((item) => {
  //     tmpSubTotal += item.unitPrice * item.amount;
  //     tmpTaxTotal += Math.floor(
  //       item.unitPrice * item.amount * (item.taxRate / 100)
  //     );
  //   });

  //   setTaxTotal(tmpTaxTotal);
  //   setSubTotal(tmpSubTotal);
  //   setTotal(tmpTaxTotal + tmpSubTotal);
  // }, [items]);

  useEffect(async () => {
    if (currentUser) {
      if (location?.state?.invoiceData) {
        setData(location.state.invoiceData);
        setLoading(false);
      } else {
        await fetchInvoiceData();
        setLoading(false);
      }
    }
  }, [currentUser]);

  return (
    <BareLayout>
      {!loading && data ? (
        <PDFViewer width="100%" height="100%">
          <Invoice data={data} title="見積書" />
        </PDFViewer>
      ) : (
        <p></p>
      )}
    </BareLayout>
  );
};

export default EstimateShow;
