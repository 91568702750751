/*eslint-disable*/

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

function AuthFooter() {
  return (
    <>
      <footer className="pb-5" id="footer-main">
        <Container>
          <Row className="align-items-center justify-content-xl-between text-white">
            <Col xl="4">
              <div className="copyright text-center text-xl-left text-white">
                © {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1 text-white"
                  href="https://chott.io"
                  target="_blank"
                >
                  Chott.inc
                </a>
              </div>
            </Col>
            <Col xl="8">
              <Nav className="nav-footer justify-content-center justify-content-xl-end text-white">
                <NavItem>
                  <NavLink className="text-white" href="/privacy">
                    プライバシーポリシー
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="text-white" href="/transaction">
                    特定商取引法に基づく表記
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="text-white" href="/terms">
                    利用規約
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="text-white" href="/">
                    INVOICYについて
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default AuthFooter;
