import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Container,
  Row,
} from "reactstrap";
import AdminLayout from "layouts/Admin.js";
import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./WorkReportCreate.module.scss";
import { db } from "../../../firebase";
import { collection, addDoc } from "firebase/firestore";
import ReportForm from "components/ReportForm/ReportForm";
import { AuthContext } from "../../../providers/AuthProvider";
import { IMAGE_TYPE } from "../../../utils/const";
import { uploadImage } from "../../../utils/common";
dayjs.locale(ja);
const WorkReportCreate = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const context = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [logoFile, setLogoFile] = useState(null);
  const [signatureFile, setSignatureFile] = useState(null);
  const [subTotal, setSubTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [reportNumber, setReportNumber] = useState();
  const [initialData, setInitialData] = useState({});
  const [reportMonth, setReportMonth] = useState(dayjs().format("YYYY-MM"));
  const [items, setItems] = useState([]);
  const [totalWorkingDays, setTotalWorkingDays] = useState(0);
  const [totalWorkingHours, setTotalWorkingHours] = useState(0);
  const onSubmit = async (data) => {
    // if (logoFile) {
    //   data.logoUrl = await uploadImage(IMAGE_TYPE.LOGO, id, logoFile);
    // }

    if (signatureFile) {
      data.signatureUrl = await uploadImage(
        IMAGE_TYPE.SIGNATURE,
        id,
        signatureFile
      );
    }

    const emp = {
      ...initialData,
      ...data,
      items: items,
      id,
      reportMonth,
      displayReportMonth: dayjs(reportMonth).format("YYYY年MM月"),
      totalWorkingDays,
      totalWorkingHours,
      createdAt: dayjs().valueOf(),
    };

    try {
      const reportsCollectionRef = collection(
        db,
        "docs",
        currentUser.uid,
        "reports"
      );
      const report = await addDoc(reportsCollectionRef, emp);

      if (report) {
        const c = Number(localStorage.getItem("rcnt")) + 1;
        localStorage.setItem("rcnt", c);
        history.push(`/reports/${id}/review`, { reportData: emp });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let tDays = 0;
    let tHours = 0;
    let tMins = 0;
    items.forEach((item) => {
      if (item?.totalHours > 0 || item?.totalMins > 0) {
        tDays++;
      }
      if (item?.totalHours) {
        tHours = tHours + item.totalHours;
      }
      if (item?.totalMins) {
        tMins = tMins + item.totalMins;
      }
    });

    tHours = tHours + Math.floor(tMins / 60);

    const amari = tMins % 60;

    const displayHM = `${tHours}:${("00" + Math.abs(tMins % 60)).slice(-2)}`;
    setTotalWorkingDays(tDays);
    setTotalWorkingHours(displayHM);
  }, [items]);

  useEffect(() => {
    const thisMonth = dayjs(reportMonth).startOf("month").format("YYYY-MM");
    const nextMonthStart = dayjs(reportMonth)
      .add(1, "month")
      .format("YYYY-MM-DD");
    const thisMonthStart = dayjs(reportMonth)
      .startOf("month")
      .format("YYYY-MM-DD");

    const diff = dayjs(nextMonthStart).diff(thisMonthStart, "day");
    const days = [];
    [...Array(diff)].map((_, i) => {
      const date = dayjs(`${thisMonth}-${i + 1}`).format("MMMD日(dd)");
      const day = dayjs(`${thisMonth}-${i + 1}`).format("dd");
      days.push({
        id: i,
        date: date,
        day: day,
        start: "00:00",
        end: "00:00",
        break: "00:00",
        dailyTotal: "00:00",
        note: "",
      });
    });
    setItems(days);
  }, [reportMonth]);

  useEffect(() => {
    const today = new Date();
    const y = today.getFullYear();
    const m = today.getMonth() + 1;
    const d = today.getDate();
    const ld = localStorage.getItem("d");

    if (d != ld) {
      localStorage.setItem("rcnt", 1);
      localStorage.setItem("d", d);
    }
    const c = localStorage.getItem("rcnt") ? localStorage.getItem("rcnt") : "1";

    setReportNumber([y, m, d, "-", c].join(""));
    const id = ("0000000" + Math.floor(Math.random() * 10000000)).slice(-7);
    setId(id);
  }, []);

  //ログイン情報格納処理
  useEffect(() => {
    if (!context?.userInfo?.uid) {
      return;
    }
    setCurrentUser(context.userInfo);
  }, [context]);

  useEffect(() => {
    if (currentUser) {
      if (location?.state?.reportData) {
        setInitialData(location.state.reportData);
      }
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {

    if (initialData?.items) {
      setItems([...initialData.items]);
    }

  }, [initialData]);

  return (
    <AdminLayout>
      {loading ? (
        <></>
      ) : (
        <Container className="mt-6 mt-xl-2" fluid>
          <Row>
            <div className="col">
              {currentUser && (
                <Card>
                  <Form>
                    <CardHeader className="border-0">
                      <h3 className="mb-0">作業報告書</h3>
                    </CardHeader>
                    <CardBody>
                      <ReportForm
                        type="report"
                        reportNumber={reportNumber}
                        initialData={initialData}
                        register={register}
                        errors={errors}
                        subTotal={subTotal}
                        taxTotal={taxTotal}
                        total={total}
                        items={items}
                        reportMonth={reportMonth}
                        setItems={setItems}
                        setReportMonth={setReportMonth}
                        setLogoFile={setLogoFile}
                        setSignatureFile={setSignatureFile}
                        totalWorkingHours={totalWorkingHours}
                        totalWorkingDays={totalWorkingDays}
                      />
                    </CardBody>
                    <CardFooter className={styles.stickyFooter}>
                      <Button
                        className="btn-icon"
                        color="default"
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                      >
                        <i class="fas fa-save"></i>
                        <span className="">保存する</span>
                      </Button>
                    </CardFooter>
                  </Form>
                </Card>
              )}
            </div>
          </Row>
        </Container>
      )}
    </AdminLayout>
  );
};

export default WorkReportCreate;
