import React from "react";

import { Pagination, PaginationItem, PaginationLink, Col } from "reactstrap";

const Paginations = ({ pageTotalNum, pageCurrNum, onClick }) => {
  const pageItems = (pageTotalNum, pageCurrNum, onClick) => {
    const limit = 5;
    const items = [];
    for (let i = 1; i <= pageTotalNum; i++) {
      if (pageCurrNum < 4) {
        if (limit >= i) {
          items.push(
            <PaginationItem
              key={i}
              className={i == pageCurrNum ? "active" : ""}
            >
              <PaginationLink onClick={() => onClick(i)}>{i}</PaginationLink>
            </PaginationItem>
          );
        }
      }
      if (pageCurrNum >= 4) {
        if (pageTotalNum - pageCurrNum < 3) {
          if (i > Number(pageTotalNum) - 5) {
            items.push(
              <PaginationItem
                key={i}
                className={i == pageCurrNum ? "active" : ""}
              >
                <PaginationLink onClick={() => onClick(i)}>{i}</PaginationLink>
              </PaginationItem>
            );
          }
        } else {
          if (Number(pageCurrNum) - 2 <= i && i <= Number(pageCurrNum) + 2) {
            items.push(
              <PaginationItem
                key={i}
                className={i == pageCurrNum ? "active" : ""}
              >
                <PaginationLink onClick={() => onClick(i)}>{i}</PaginationLink>
              </PaginationItem>
            );
          }
        }
      }
      // items.push(
      //   <PaginationItem key={i} className={i == pageCurrNum ? "active" : ""}>
      //     <PaginationLink onClick={() => onClick(i)}>{i}</PaginationLink>
      //   </PaginationItem>
      // );
    }
    return <>{items}</>;
  };

  return (
    <>
      <div className="pagination-container justify-content-center">
        <Pagination
          className={"pagination pagination-primary"}
          listClassName={" pagination-primary ml-auto"}
        >
          <PaginationItem disabled={Number(pageCurrNum) === 1 ? true : false}>
            <PaginationLink
              previous
              onClick={() => onClick(Number(pageCurrNum) - 1)}
            />
          </PaginationItem>
          {pageItems(pageTotalNum, pageCurrNum, onClick)}
          <PaginationItem disabled={pageCurrNum == pageTotalNum ? true : false}>
            <PaginationLink
              next
              onClick={() => onClick(Number(pageCurrNum) + 1)}
            />
          </PaginationItem>
        </Pagination>
      </div>
    </>
  );
};

export default Paginations;
