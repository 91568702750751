import React, { useState, useContext, createContext } from "react";

export const AuthContext = createContext({});

export const AuthProvider = (props) => {
  const [authLoading, setAuthLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const { children } = props;
  return (
    <AuthContext.Provider
      value={{ userInfo, setUserInfo, authLoading, setAuthLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};
