import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Container,
  Row,
} from "reactstrap";
import AdminLayout from "layouts/Admin.js";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./EstimateCreate.module.scss";
import { db } from "../../../firebase";
import { collection, addDoc } from "firebase/firestore";
import InvoiceForm from "components/InvoiceForm/InvoiceForm";
import { AuthContext } from "../../../providers/AuthProvider";
import { IMAGE_TYPE } from "../../../utils/const";
import { uploadImage } from "../../../utils/common";
import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
dayjs.locale(ja);
const EstimateCreate = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const context = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [logoFile, setLogoFile] = useState(null);
  const [signatureFile, setSignatureFile] = useState(null);
  const [subTotal, setSubTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [initialData, setInitialData] = useState({});
  const [items, setItems] = useState([
    {
      id: "1",
      title: "",
      amount: "",
      unit: "",
      unitPrice: "",
      taxRate: 10,
    },
    {
      id: "2",
      title: "",
      amount: "",
      unit: "",
      unitPrice: "",
      taxRate: 10,
    },
    {
      id: "3",
      title: "",
      amount: "",
      unit: "",
      unitPrice: "",
      taxRate: 10,
    },
  ]);

  const onSubmit = async (data) => {
    if (logoFile) {
      data.logoUrl = await uploadImage(IMAGE_TYPE.LOGO, id, logoFile);
    }

    if (signatureFile) {
      data.signatureUrl = await uploadImage(
        IMAGE_TYPE.SIGNATURE,
        id,
        signatureFile
      );
    }

    const emp = {
      ...initialData,
      ...data,
      items: items,
      id,
      subTotal,
      taxTotal,
      total,
      createdAt: dayjs().valueOf(),
    };

    try {
      const estimatesCollectionRef = collection(
        db,
        "docs",
        currentUser.uid,
        "estimates"
      );
      const invoice = await addDoc(estimatesCollectionRef, emp);

      if (invoice) {
        const c = Number(localStorage.getItem("ecnt")) + 1;
        localStorage.setItem("ecnt", c);
        history.push(`/estimates/${id}/review`, { invoiceData: emp });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let tmpSubTotal = 0;
    let tmpTaxTotal = 0;

    items.forEach((item) => {
      tmpSubTotal += item.unitPrice * item.amount;
      tmpTaxTotal += Math.floor(
        item.unitPrice * item.amount * (item.taxRate / 100)
      );
    });

    setTaxTotal(tmpTaxTotal);
    setSubTotal(tmpSubTotal);
    setTotal(tmpTaxTotal + tmpSubTotal);
  }, [items]);

  useEffect(() => {
    const today = new Date();
    const y = today.getFullYear();
    const m = today.getMonth() + 1;
    const d = today.getDate();
    const ld = localStorage.getItem("d");

    if (d != ld) {
      localStorage.setItem("ecnt", 1);
      localStorage.setItem("d", d);
    }
    const c = localStorage.getItem("ecnt") ? localStorage.getItem("ecnt") : "1";

    setInvoiceNumber([y, m, d, "-", c].join(""));
    const id = ("0000000" + Math.floor(Math.random() * 10000000)).slice(-7);
    setId(id);
  }, []);

  //ログイン情報格納処理
  useEffect(() => {
    if (!context?.userInfo?.uid) {
      return;
    }
    setCurrentUser(context.userInfo);
  }, [context]);

  useEffect(() => {
    if (currentUser) {
      if (location?.state?.invoiceData) {
        setInitialData(location.state.invoiceData);
      }
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    let tmpSubTotal = 0;
    let tmpTaxTotal = 0;

    if (initialData?.items) {
      setItems([...initialData.items]);
      initialData?.items.forEach((item) => {
        tmpSubTotal += item.unitPrice * item.amount;
        tmpTaxTotal += Math.floor(
          item.unitPrice * item.amount * (item.taxRate / 100)
        );
      });
    }

    setTaxTotal(tmpTaxTotal);
    setSubTotal(tmpSubTotal);
    setTotal(tmpTaxTotal + tmpSubTotal);
  }, [initialData]);

  return (
    <AdminLayout>
      {loading ? (
        <></>
      ) : (
        <Container className="mt-6 mt-xl-2" fluid>
          <Row>
            <div className="col">
              {currentUser && (
                <Card>
                  <Form>
                    <CardHeader className="border-0">
                      <h3 className="mb-0">見積書</h3>
                    </CardHeader>
                    <CardBody>
                      <InvoiceForm
                        type="estimate"
                        invoiceNumber={invoiceNumber}
                        initialData={initialData}
                        register={register}
                        errors={errors}
                        subTotal={subTotal}
                        taxTotal={taxTotal}
                        total={total}
                        items={items}
                        setItems={setItems}
                        setLogoFile={setLogoFile}
                        setSignatureFile={setSignatureFile}
                      />
                    </CardBody>
                    <CardFooter className={styles.stickyFooter}>
                      <Button
                        className="btn-icon"
                        color="default"
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                      >
                        <i class="fas fa-save"></i>
                        <span className="">保存する</span>
                      </Button>
                    </CardFooter>
                  </Form>
                </Card>
              )}
            </div>
          </Row>
        </Container>
      )}
    </AdminLayout>
  );
};

export default EstimateCreate;
