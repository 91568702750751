import React, { useState, useEffect, useContext } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import {
  Text,
  View,
  PDFDownloadLink,
  Page,
  Document,
} from "@react-pdf/renderer";
import BareLayout from "layouts/Bare.js";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useForm, Controller } from "react-hook-form";
import compose from "../../../styles/compose";
import styles from "./InvoiceReview.module.scss";
import Invoice from "../../../components/Pdf/Invoice";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { db, storageRef } from "../../../firebase";
import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  where,
  query,
} from "firebase/firestore";
import { AuthContext } from "../../../providers/AuthProvider";
import { PDFViewer } from "@react-pdf/renderer";
import { getDownloadURL } from "firebase/storage";

const InvoiceShow = () => {
  // const { register, handleSubmit, watch, errors, control } = useForm();
  const { id } = useParams();
  const location = useLocation();
  // const history = useHistory();
  // const [subTotal, setSubTotal] = useState(0);
  // const [taxTotal, setTaxTotal] = useState(0);
  // const [total, setTotal] = useState(0);
  const [data, setData] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const context = useContext(AuthContext);
  //ログイン情報格納処理
  useEffect(() => {
    if (!context?.userInfo?.uid) {
      return <></>;
    }
    setCurrentUser(context.userInfo);
  }, [context]);

  const fetchInvoiceData = async () => {
    try {
      const invoicesCollectionRef = collection(
        db,
        "docs",
        currentUser.uid,
        "invoices"
      );
      const q = query(invoicesCollectionRef, where("id", "==", id));

      const querySnapshot = await getDocs(q);
      let d;
      querySnapshot.forEach((doc) => {
        d = doc.data();
        setData(d);
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  // useEffect(() => {
  //   let tmpSubTotal = 0;
  //   let tmpTaxTotal = 0;

  //   items?.forEach((item) => {
  //     tmpSubTotal += item.unitPrice * item.amount;
  //     tmpTaxTotal += Math.floor(
  //       item.unitPrice * item.amount * (item.taxRate / 100)
  //     );
  //   });

  //   setTaxTotal(tmpTaxTotal);
  //   setSubTotal(tmpSubTotal);
  //   setTotal(tmpTaxTotal + tmpSubTotal);
  // }, [items]);

  useEffect(async () => {
    if (currentUser) {
      if (location?.state?.invoiceData) {
        setData(location.state.invoiceData);
        setLoading(false);
      } else {
        await fetchInvoiceData();
        setLoading(false);
      }
    }
  }, [currentUser]);

  return (
    <BareLayout>
      {!loading && data ? (
        <PDFViewer width="100%" height="100%">
          <Invoice data={data} title="請求書" />
        </PDFViewer>
      ) : (
        <p></p>
      )}
    </BareLayout>
  );
};

export default InvoiceShow;
