import React, { useState, useEffect, useLayoutEffect, useContext } from "react";

import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import AuthHeader from "components/Headers/AuthHeader.js";
import Auth from "layouts/Auth";
import { login, sendPasswordResetLink } from "../../../firebase";
import { AuthContext } from "../../../providers/AuthProvider";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
function Password() {
  const history = useHistory();
  const context = useContext(AuthContext);
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [loading, setLoading] = useState(true);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const res = sendPasswordResetLink(data.email);
      if (res) {
        setIsSuccess(true);
        setIsError(false);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    if (context.userInfo) {
      history.push("/invoices");
    }
    if (!context.authLoading) {
      setLoading(false);
    }
  }, [context.authLoading, context.userInfo]);

  return (
    <Auth>
      <AuthHeader title="" lead="" />
      <Container className="mt--8 pb-8 mb-3">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            {isSuccess ? (
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent pb-4">
                  <div className="text-center mt-2 mb-3 font-weight-bold">
                    <h1>
                      <small>Success</small>
                    </h1>
                    <small>
                      パスワードのリセットリンクがご登録のメール宛に送信されました。
                      <br />
                      メールボックスをご確認ください。
                      <br />
                    </small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center">
                    <Button className="my-4" href="/login" color="default">
                      ログインページへ戻る
                    </Button>
                  </div>
                </CardBody>
              </Card>
            ) : (
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent pb-4">
                  <div className="text-center mt-2 mb-3 font-weight-bold">
                    <h1>
                      <small>パスワードをリセット</small>
                    </h1>
                    {isError && (
                      <p className="text-red">
                        エラーが発生しました。
                        <br />
                        しばらく経ってから再度やり直してください。
                      </p>
                    )}
                    <small>
                      ご登録のメールを入力してください。
                      <br />
                      パスワードのリセットリンクがご登録のメール宛に送信されます。
                      <br />
                    </small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          {...register("email", {
                            required: {
                              value: true,
                              message: "メールは必須項目です",
                            },
                          })}
                          className={`form-control ${
                            errors?.email?.message ? "border-danger" : ""
                          }`}
                          className="form-control"
                          name="email"
                          placeholder="メール"
                          type="email"
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(false)}
                        />
                      </InputGroup>
                      {errors?.email?.message && (
                        <span className="text-red">{errors.email.message}</span>
                      )}
                    </FormGroup>

                    <div className="text-center">
                      <Button className="my-4" color="default" type="submit">
                        リセットリンクを送信する
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            )}
            <Row className="mt-3">
              <Col xs="6">
                <Link className="text-light cursor-pointer" to="/login">
                  <small>ログインページはこちら</small>
                </Link>
              </Col>
              <Col className="text-right" xs="6">
                <Link className="text-light cursor-pointer" to="/register">
                  <small>新規登録はこちら</small>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Auth>
  );
}

export default Password;
