import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import styles from "./Error404.module.scss";
// Core Components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import ErrorLayout from "layouts/ErrorLayout";
function Error404() {
  React.useEffect(() => {
    document.body.classList.add("error-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("error-page");
    };
  });
  return (
    <ErrorLayout>
      <AuthNavbar />
      <div className="wrapper">
        <div className="page-header error-page">
          <Container className={styles.errorContainer}>
            <h1 className={` ${styles.title}`}>404</h1>
            <p className="lead text-white">お探しのページが見つかりません。</p>
          </Container>
        </div>
      </div>
    </ErrorLayout>
  );
}

export default Error404;
