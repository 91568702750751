import React, { useState, useEffect } from "react";

import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Helmet } from "react-helmet";
import AuthHeader from "components/Headers/AuthHeader.js";
import Auth from "layouts/Auth";
import {
  signup,
  signupWithGoogle,
  signinWithGithub,
  useAuth,
} from "../../../firebase";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
function Register() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [isError, setIsError] = useState(false);
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const currentUser = useAuth();
  const history = useHistory();
  const handleSignup = async (email, password) => {
    const user = await signup(email, password);
    if (user) {
      history.push("/invoices");
    }
  };

  const onSubmit = async (data) => {
    try {
      await handleSignup(data.email, data.password);
      setIsError(false);
    } catch (error) {
      setIsError(true);
    }
  };

  const handleSignInWithGoogle = () => {
    try {
      signupWithGoogle();
      setIsError(false);
    } catch (error) {
      setIsError(true);
    }
  };

  const handleSignInWithGitHub = () => {
    try {
      signinWithGithub();
      setIsError(false);
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    if (currentUser?.currentUser) {
      history.push("/invoices");
    } else {
      setLoading(false);
    }
  }, [currentUser]);

  if (loading) {
    return <div></div>;
  }
  return (
    <>
      <Helmet>
        <title>INVOICY サインイン</title>
        <meta
          property="og:description"
          content="完全無料
フリーランス向け
請求書、作業報告書管理ツール INVOICY(インボイシークラウド)　| サインイン"
        />
        <meta
          name="description"
          content="完全無料
フリーランス向け
請求書、作業報告書管理ツール INVOICY(インボイシークラウド)　| サインイン"
        />
      </Helmet>
      <Auth>
        <AuthHeader title="" lead="" />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-4">
                    <h1>
                      <small>インボイシーアカウントの作成</small>
                    </h1>

                    <small>
                      サービス利用にはインボイシーIDが必要です。
                      <br />
                      下記サービスもしくは、
                      <br />
                      メール&パスワードで登録してください。
                    </small>
                    {isError && (
                      <p className="text-red">
                        アカウント作成に失敗しました。
                        <br />
                        メールをご確認の上、再度お試しください。
                      </p>
                    )}
                  </div>
                  <div className="text-center">
                    <Button
                      className="btn-neutral btn-icon mr-4"
                      color="default"
                      onClick={handleSignInWithGitHub}
                    >
                      <span className="btn-inner--icon mr-1">
                        <img
                          alt="..."
                          src={
                            require("assets/img/icons/common/github.svg")
                              .default
                          }
                        />
                      </span>
                      <span className="btn-inner--text">Github</span>
                    </Button>
                    {/* <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    onClick={handleSignInWithGoogle}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/google.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Google</span>
                  </Button> */}
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>メールとパスワードで登録</small>
                  </div>
                  <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup
                      className={classnames({
                        focused: focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          {...register("email", {
                            required: {
                              value: true,
                              message: "メールは必須項目です",
                            },
                          })}
                          className={`form-control ${
                            errors?.email?.message ? "border-danger" : ""
                          }`}
                          name="email"
                          placeholder="メール"
                          type="email"
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(false)}
                        />
                      </InputGroup>
                      {errors?.email?.message && (
                        <span className="text-red">{errors.email.message}</span>
                      )}
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: focusedPassword,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative mt-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          {...register("password", {
                            required: {
                              value: true,
                              message: "パスワードは必須項目です。",
                            },
                            minLength: {
                              value: 8,
                              message:
                                "パスワードは8文字以上で入力してください",
                            },
                          })}
                          className={`form-control ${
                            errors?.password?.message ? "border-danger" : ""
                          }`}
                          name="password"
                          type="password"
                          placeholder="パスワード"
                          onFocus={() => setfocusedPassword(true)}
                          onBlur={() => setfocusedPassword(false)}
                        />
                      </InputGroup>
                      {errors?.password?.message && (
                        <span className="text-red">
                          {errors.password.message}
                        </span>
                      )}
                    </FormGroup>

                    <Row className="my-4">
                      <Col xs="12">
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <span className="text-muted">
                            INVOICY<Link to="">利用規約</Link>、
                            <Link to="/privacy">プライバシーポリシー</Link>
                            に同意の上、ご登録ください。
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <div className="text-center">
                      <Button className="mt-4" color="default" type="submit">
                        上記に同意して登録する
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <Link className="text-light cursor-pointer" to="/password">
                    パスワードを忘れた方はこちら
                  </Link>
                </Col>
                <Col className="text-right cursor-pointer" xs="6">
                  <Link className="text-light" to="/login">
                    既にアカウントをお持ちの方はこちら
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Auth>
    </>
  );
}

export default Register;
