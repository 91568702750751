import React from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardImgOverlay,
  CardTitle,
  Collapse,
} from "reactstrap";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import IndexFooter from "components/Footers/IndexFooter.js";
const TransactionLaw = () => {
  return (
    <>
      <IndexNavbar />
      <div className="main-content mt-40">
        <section className="py-6 pb-9 bg-white">
          <Container fluid className="docs">
            <Row className="justify-content-center">
              <Col md="8">
                <h2 className="display-4">特定商取引法に基づく表記</h2>
                <hr />
                <p>
                  <h4>事業者名</h4>
                  <p>株式会社Chott</p>
                  <h4>事業所在地</h4>
                  <p>東京都渋谷区</p>
                  <h4>連絡先／ホームページ</h4>
                  <p>
                    <a href="">https://invoicy-cloud.com</a>
                  </p>
                  <h4>連絡先／電子メール</h4>
                  <p>
                    <a href="">info@invoicy-cloud.com</a>
                  </p>
                  <h4>サービス利用料金</h4>
                  <p>
                    サービスごとにサービス利用料金（消費税等含む）を設定し、該当ページで表示します。
                  </p>
                  <h4>サービス利用料金の支払時期および方法</h4>
                  <p>
                    サービスごとにサービス利用料金（消費税等含む）を設定し、該当ページで表示します。
                  </p>
                  <h4>サービス利用料金以外に必要な費用</h4>
                  <p>
                    インターネット接続料金その他の通信料金等の他、請求書郵送ごとに郵送料金が必要です。
                  </p>
                  <h4>サービスの提供条件</h4>
                  <p>インターネットにてサービスを提供いたします。</p>
                  <h4>サービスの提供時期</h4>
                  <p>
                    お申し込み手続き完了後、お申込みいただいたプランについてのサービスと各種サポートを利用できます。
                  </p>
                  <h4>サービスの申込撤回または解除の取扱条件</h4>
                  <p>
                    本サイ卜で提供するサービスについては、契約手続完了後のキャンセルをお受けいたしません。なお、サービスに瑕庇がある場合は、利用規約の定めに従って対応します。
                    特別な提供条件があるサービスについては、各サービスの提供ページにおいて条件を表示します。
                  </p>
                  <h4>無保証任</h4>
                  <p>
                    弊社は、提供する役務について一切の保証を行わず、提供された役務に起因してお客さまが被った損害又は損失等について、一切の責任を負わないものとします。
                  </p>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </div>

      <IndexFooter />
    </>
  );
};

export default TransactionLaw;
