import { IMAGE_TYPE } from "./const";

import { storageRef, logoRef, signatureRef } from "../firebase";
import { uploadBytesResumable, deleteObject } from "firebase/storage";

export const chunkArrayInGroups = (arr, size) => {
  let newArr = [];
  let i = 0;
  while (i < arr.length) {
    newArr.push(arr.slice(i, i + size));
    i += size;
  }
  return newArr;
};

export const formatDate = (date) => {
  return `${date.replace("-", "年").replace("-", "月")}日`;
};

export const uploadImage = async (type, id, file) => {
  let imgRef;
  if (type === IMAGE_TYPE.LOGO) {
    imgRef = await logoRef(id, file);
  } else if (type === IMAGE_TYPE.SIGNATURE) {
    imgRef = await signatureRef(id, file);
  }

  return await uploadBytesResumable(imgRef, file)
    .then((snapshot) => {
      return snapshot.metadata.fullPath;
    })
    .catch((error) => {
      console.error("Upload failed", error);
    });
};

export const deleteImage = async (path) => {
  const ref = await storageRef(path);
  return await deleteObject(ref)
    .then(() => {
      console.log("success");
    })
    .catch((error) => {
      console.log("error", error);
    });
};
