import React from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardImgOverlay,
  CardTitle,
  Collapse,
} from "reactstrap";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import IndexFooter from "components/Footers/IndexFooter.js";
const PrivacyPolicy = () => {
  return (
    <>
      <IndexNavbar />
      <div className="main-content mt-40">
        <section className="py-6 pb-9 bg-white text-sm">
          <Container fluid className="docs">
            <Row className="justify-content-center">
              <Col md="8">
                <h2 className="display-4">個人情報保護方針</h2>
                <hr />
                <div>
                  <p>
                    株式会社Chott（以下「当社」という。）は、当社の提供するサービスをご利用されるユーザーの個人情報の取扱について以下のとおり定め、個人情報の保護に努めてまいります。なお、個人情報保護方針は予告なく随時修正又は変更されることがあります。最新の個人情報保護方針は、このページに記載され公表されますので、随時ご確認ください。
                  </p>
                  <ol>
                    <li>
                      <h4>法令・指針・規範の遵守</h4>

                      <p>
                        当社は個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守致します。
                      </p>
                    </li>
                    <li>
                      <h4>個人情報の取得・利用・提供</h4>

                      <p>
                        個人情報の取得に際しては、その利用目的をできる限り特定し、利用目的の達成に必要な範囲を超えた個人情報の取り扱いを行わないものとします。また、取得した個人情報の適切な利用・提供のための措置を講じます。
                      </p>
                    </li>
                    <li>
                      <h4>個人情報の外部提供</h4>

                      <p>
                        当社は、個人情報の取り扱いの全部または一部を外部に委託する場合には、十分な保護水準を満たした者を選定し、契約等により適切な措置を講じます。
                      </p>
                    </li>
                    <li>
                      <h4>個人情報に対する安全管理措置</h4>

                      <p>
                        当社は、保有する個人情報を利用目的の達成に必要な範囲内において、個人情報の漏えい、滅失又は毀損などのおそれに対して、防止と是正のための措置を講じます。
                      </p>
                    </li>
                    <li>
                      <h4>個人情報に関するお問い合わせ</h4>

                      <p>
                        当社は、個人情報の取り扱いに関する苦情および相談への適切かつ迅速な対応に努めます。
                      </p>
                    </li>
                    <li>
                      <h4>継続的改善</h4>

                      <p>
                        当社は、個人情報保護マネジメントシステムの継続的改善を行います。
                      </p>
                    </li>
                  </ol>
                  <div className="text-right">
                    <p>
                      制定：2022年1月29日
                      <br />
                      最終改定：2022年1月29日
                      <br />
                      株式会社Chott
                      <br />
                      代表取締役社長　小原　大輝
                      <br />
                    </p>
                  </div>
                  <ol>
                    <li>
                      <h4>利用者情報の取扱いについて</h4>

                      <p>
                        株式会社Chott（以下「当社」といいます。）は、当社の提供するサービス（以下「当社サービス」といいます。）における利用者（以下「ご利用者様」といいます。）についての個人情報を含む利用者情報の取扱について、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
                        なお、当社は、ご利用者様が他社に対し、第三者提供を行うことに同意して提供した情報について、当該他社の同意を得て、本件サービス上にて、当該ご利用者様に対し画面上で表示し、又は、当該情報に基づいて金融商品・サービスの提案・提供等を行うことがあります。
                      </p>
                    </li>
                    <li>
                      <h4>利用者情報及び個人情報の定義について</h4>

                      <p>
                        本ポリシーにおいて、「利用者情報」とは、ご利用者様の識別に係る情報、通信サービス上の行動履歴、その他ご利用者様またはご利用者様の端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。なお、利用者情報には個人情報に該当する情報と該当しない情報とが含まれます。
                        また、個人情報とは、個人情報の保護に関する法律に定義されている「個人情報」をいい、ご利用者様その他⽣存する個人に関する情報であって、当該情報に含まれる⽒名、⽣年⽉⽇、住所、電話番号、メールアドレスその他の記述等により当該個人を識別することができるもの及び個人識別符号を指します。なお、個人情報には、その内容のみでは当該個人を識別することができない場合でも、他の情報と容易に照合することができ、それにより当該個人を識別することができることとなるものも含まれます。
                      </p>
                    </li>
                    <li>
                      <h4>収集する利用者情報について</h4>

                      <ul>
                        <li>
                          <p className="mb-0">
                            氏名、生年月日、職業等のプロフィールに関する情報
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            メールアドレス、電話番号、住所等連絡先に関する情報
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">ログインに必要な識別情報</p>
                        </li>
                        <li>
                          <p className="mb-0">
                            クッキー技術を用いて識別された識別情報
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            入力フォームその他当社が定める方法を通じてご利用者様が入力または送信する情報（画像を含みます。）
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            ご利用者様が当社サービスを利用するに当たり他のサービスとの連携を許可した場合における、当該外部サービスでご利用者様が利用するIDその他当該外部サービスのプライバシー設定によりご利用者様が連携先に開示を認めた情報
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            ご利用者様と当社との間のメールや通話の内容及び通信履歴
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            ご利用者様のウェブページ上の行動履歴
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            ご利用者様が当社サービスを利用する際に締結する契約情報
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            ご利用者様が本サービスを利用するにあたって、当社がご利用者様の個別同意に基づいて収集する情報
                          </p>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <h4 className="mt-3">利用⽬的について</h4>

                      <p>
                        当社は、お客様にご提供いただいた個人情報は機密扱いとなり、お客様と当社との顧客関係に役立てるため、または当社および当社の関連会社の業務もしくはサービスの向上のために利用されます。
                      </p>

                      <ul>
                        <li>
                          <p className="mb-0">当社サービスの提供のため</p>
                        </li>
                        <li>
                          <p className="mb-0">
                            当社サービスに関する情報提供のため
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            当社サービスの利用状況等を統計的に分析するため
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            当社のサービスの改善及び開発のため
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            お問い合わせ対応の品質向上及びお問合せ内容等の正確な把握のため
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            マーケティング及びキャンペーンでの利用のため
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            取得した情報を統計的に処理した結果を公表するため
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            当社や第三者の広告を配信または表示するため
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">上記に付随する業務遂⾏や連絡</p>
                        </li>
                        <li>
                          <p className="mb-0">⼿続き対応のため</p>
                        </li>
                        <li>
                          <p className="mb-0">
                            お問い合わせ対応の品質向上及びお問合せ内容等の正確な把握のため
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">上記に付随する業務遂行や連絡</p>
                        </li>
                        <li>
                          <p className="mb-0">手続き対応のため</p>
                        </li>
                        <li>
                          <p className="mb-0">
                            不正利用の防止及び調査並びに不正利用が発見された際の連絡等に用いるため
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <h4 className="mt-3">個人情報の第三者提供について</h4>

                      <p>
                        お客様にご提供いただいた個人情報は、お客様の同意がある場合または下記の場合に第三者に開示・提供することがございます。それ以外の場合に、第三者に開示・提供することはありません。
                      </p>

                      <ul>
                        <li>
                          <p className="mb-0">
                            上記「個人情報の利用目的」記載の目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を他社に委託する場合
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            商品等をご購入いただいた際のお支払いについて、銀行など金融機関に、必要な情報を提供する場合
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            お客様が、当社に損害を生じさせ、あるいはその虞があると当社が判断した場合
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            裁判所、弁護士会、警察、消費者センターなど公的機関から照会を受けた場合
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">その他、法令の定めのある場合</p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <h4>個人情報の取扱いの委託について</h4>
                      <p>
                        当社では、利用⽬的の達成に必要な範囲内において個人情報の取扱いの全部⼜は⼀部を外部委託先（日本国外にある者を含みます。）に委託する場合がございます。
                        当社は、委託先に対しては個人情報保護・情報セキュリティの観点から評価を⾏った上で適切な委託先を選定するとともに、委託先と安全管理措置の内容を含む適切な契約を締結し、取扱いを委託した個人情報の安全管理が図られるよう、委託先に対する必要かつ適切な監督を実施いたします。
                      </p>
                    </li>
                    <li>
                      <h4>Cookie等の利用について</h4>
                      <p>
                        当社は、ウェブサイト内において、不正防止、ウェブサイトの使い勝手の向上、アクセス状況などの統計情報の取得、広告効果測定等の目的で、ログファイルの収集、当社もしくは第三者によるCookieの送信、ウェブビーコンの設置等により、ご利用者様のCookie情報、IPアドレス、アクセス回数、ご利用環境等の情報を取得いたします。Cookieはご利用者様の設定により使用を禁止することが可能です。Cookieの使用を禁止した場合、当社サービスを正常に利用できなくなる可能性があります。
                      </p>
                    </li>
                    <li>
                      <h4>匿名加工情報について</h4>
                      <p>
                        当社は、本ポリシーに同意いただいた本人の個人情報について、法第36条第1項の個人情報保護委員会規則で定める基準に従って特定の個人を識別することおよび作成に用いる個人情報を復元することができないよう適切な保護措置を講じたうえで、匿名加工情報の作成と第三者への提供を法令で認められた範囲で継続的に実施します。
                      </p>
                    </li>
                    <li>
                      <h4>プライバシーポリシーの変更について</h4>
                      <p>
                        当社のプライバシーポリシーを大幅に変更する場合は、今後当社のウェブサイトで告知いたします。
                      </p>
                    </li>
                  </ol>
                  <div className="text-right">
                    <p>
                      個人情報に関するお問い合わせ
                      <br />
                      株式会社Chott 個人情報相談窓口 <br />
                      連絡先：info@invoicy-cloud.com
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <IndexFooter />
    </>
  );
};

export default PrivacyPolicy;
