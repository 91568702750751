import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  Col,
  Badge,
} from "reactstrap";
import Dropzone from "components/Dropzone/Dropzone";
import ReportFormModal from "components/ReportFormModal/ReportFormModal";
import { useForm, Controller } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styles from "./ReportForm.module.scss";
import { db, useAuth, storageRef } from "../../firebase";
import { getDownloadURL } from "firebase/storage";
import dayjs from "dayjs";

const ReportForm = ({
  type,
  reportNumber,
  initialData,
  register,
  errors,
  items,
  reportMonth,
  setItems,
  setReportMonth,
  setLogoFile,
  setSignatureFile,
  totalWorkingDays,
  totalWorkingHours,
}) => {
  const [logoPreSignedUrl, setLogoPreSignedUrl] = useState();
  const [signaturePreSignedUrl, setSignaturePreSignedUrl] = useState();
  const [modal, setModal] = useState(false);
  const [bulkApply, setBulkApply] = useState(false);
  const handleApplyInput = (data) => {
    let tmp = [...items];
    setBulkApply(data);
    tmp.forEach((item, index) => {
      if (data.activeDays.includes(item.day)) {
        tmp[index]["start"] = data.start;
        tmp[index]["end"] = data.end;
        tmp[index]["break"] = data.breakTime;
        tmp[index]["note"] = data.note;
        tmp[index]["dailyTotal"] = "00:00";
        tmp[index]["totalHours"] = 0;
        tmp[index]["totalMins"] = 0;
        console.log("hogehoge");
        if (data.start && data.end) {
          const { dailyTotal, totalHours, totalMins } = calcWorkingHours(
            data.start,
            data.end,
            data.breakTime
          );

          tmp[index]["dailyTotal"] = dailyTotal;
          tmp[index]["totalHours"] = totalHours;
          tmp[index]["totalMins"] = totalMins;
        }
      } else {
        tmp[index]["start"] = "00:00";
        tmp[index]["end"] = "00:00";
        tmp[index]["break"] = "00:00";
        tmp[index]["note"] = "";
      }
    });

    setItems([...tmp]);
  };

  const handleChangeText = (title, value, index) => {
    let tmp = [...items];
    tmp[index][title] = value;
    setItems([...tmp]);
  };

  const calcWorkingHours = (start, end, breakTime) => {
    if (start && end) {
      debugger;
      let [start_hour, start_minute] = start ? start.split(":") : null;

      let [end_hour, end_minute] = end ? end.split(":") : null;
      let break_hour = 0;
      let break_minute = 0;
      if (breakTime) {
        [break_hour, break_minute] = breakTime.split(":");
      }

      const tmpStart = start ? start.replace(":", "") : null;
      const tmpEnd = end ? end.replace(":", "") : null;

      if (Number(tmpStart) > Number(tmpEnd)) {
        end_hour = Number(end_hour) + 24;
      }

      let totalHours = end_hour - start_hour - break_hour;

      let totalMins =
        Number(end_minute) - Number(start_minute) - Number(break_minute);

      if (totalMins < 0) {
        totalHours = totalHours - 1;
        if (totalMins < Number(-60)) {
          totalHours = totalHours - 1;
          totalMins = 60 + (60 + totalMins);
        } else {
          totalMins = 60 + totalMins;
        }
      }

      const displayTotalHour =
        totalHours < 0
          ? "-" + ("00" + Math.abs(totalHours)).slice(-2)
          : ("00" + Math.abs(totalHours)).slice(-2);
      const displayTotalMin = ("00" + Math.abs(totalMins)).slice(-2);

      let dailyTotal = `${displayTotalHour}:${displayTotalMin}`;

      return {
        dailyTotal,
        totalHours: Number(totalHours),
        totalMins: Number(totalMins),
      };
    }
  };

  const handleChangeInput = (title, value, index) => {
    let tmp = [...items];
    const titles = ["start", "end", "break"];
    tmp[index][title] = value;

    const start = tmp[index]["start"]
      ? tmp[index]["start"].replace(":", "")
      : null;
    const end = tmp[index]["end"] ? tmp[index]["end"].replace(":", "") : null;

    if (titles.includes(title) && start && end) {
      let [start_hour, start_minute] = tmp[index]["start"]
        ? tmp[index]["start"].split(":")
        : null;

      let [end_hour, end_minute] = tmp[index]["end"]
        ? tmp[index]["end"].split(":")
        : null;
      let break_hour = 0;
      let break_minute = 0;
      if (tmp[index]["break"]) {
        [break_hour, break_minute] = tmp[index]["break"]
          ? tmp[index]["break"].split(":")
          : null;
      }

      if (Number(start) > Number(end)) {
        end_hour = Number(end_hour) + 24;
      }

      let totalHours = end_hour - start_hour - break_hour;

      let totalMins =
        Number(end_minute) - Number(start_minute) - Number(break_minute);

      if (totalMins < 0) {
        totalHours = totalHours - 1;
        if (totalMins < Number(-60)) {
          totalHours = totalHours - 1;
          totalMins = 60 + (60 + totalMins);
        } else {
          totalMins = 60 + totalMins;
        }
      }

      const displayTotalHour =
        totalHours < 0
          ? "-" + ("00" + Math.abs(totalHours)).slice(-2)
          : ("00" + Math.abs(totalHours)).slice(-2);
      const displayTotalMin = ("00" + Math.abs(totalMins)).slice(-2);
      tmp[index]["dailyTotal"] = `${displayTotalHour}:${displayTotalMin}`;
      tmp[index]["totalHours"] = Number(totalHours);
      tmp[index]["totalMins"] = Number(totalMins);
    }
    setItems([...tmp]);
  };
  const getPresignedUrl = async (path) => {
    const tmp = await storageRef(path);

    const pre = await getDownloadURL(tmp);

    return pre;
  };
  useEffect(async () => {
    if (initialData?.signatureUrl) {
      const signaturePresignedUrl = await getPresignedUrl(
        initialData.signatureUrl
      );
      setSignaturePreSignedUrl(signaturePresignedUrl);
    }
  }, [initialData?.signatureUrl]);

  return (
    <>
      <Row>
        <Col lg="6">
          <h6 className="heading-small text-muted mb-4">基本情報</h6>
          <div className="">
            <Row>
              <Col lg="9">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    稼働先
                    <Badge className="ml-2" color="default">
                      必須
                    </Badge>
                  </label>
                  <input
                    className={`form-control ${
                      errors?.toName?.message ? "border-danger" : ""
                    }`}
                    id="toName"
                    placeholder="例) 株式会社Chott"
                    defaultValue={initialData.toName}
                    type="text"
                    {...register("toName", {
                      required: {
                        value: true,
                        message: "稼働先は入力必須項目です。",
                      },
                    })}
                  />
                  {errors?.toName?.message && (
                    <span className="text-red">{errors.toName.message}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="reportNumber">
                    作業報告書番号
                  </label>
                  <input
                    className="form-control"
                    id="reportNumber"
                    placeholder="00000000"
                    type="text"
                    defaultValue={reportNumber}
                    {...register("reportNumber", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
            </Row>

            {/* <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="title">
                    件名
                  </label>
                  <input
                    className="form-control"
                    id="title"
                    placeholder=""
                    type="text"
                    {...register("title", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
            </Row> */}
          </div>
        </Col>

        <Col lg="6" className="border-md-left border-light">
          <h6 className="heading-small text-muted mb-4">稼働者情報</h6>
          <div className="">
            <Row>
              <Col lg="9">
                <FormGroup>
                  <label className="form-control-label" htmlFor="fromName">
                    氏名
                  </label>
                  <input
                    className={`form-control ${
                      errors?.fromName?.message && "border-danger"
                    }`}
                    id="fromName"
                    placeholder="例）山田　太郎"
                    type="text"
                    defaultValue={initialData?.fromName}
                    {...register("fromName", {
                      required: false,
                    })}
                  />
                  {errors?.fromName?.message && (
                    <span className="text-red">{errors.fromName.message}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            {/* <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="fromCompanyName"
                  >
                    所属(会社名/屋号)
                  </label>
                  <input
                    className="form-control"
                    id="fromCompanyName"
                    placeholder="例) 株式会社Chott"
                    type="text"
                    defaultValue={initialData?.fromCompanyName}
                    {...register("fromCompanyName", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
            </Row> */}
            {/* <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="tel">
                    TEL
                  </label>
                  <input
                    className="form-control"
                    id="tel"
                    defaultValue={initialData?.tel}
                    placeholder="例) 03-0000-0000"
                    type="text"
                    {...register("tel", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="mail">
                    メール
                  </label>
                  <input
                    className="form-control"
                    id="mail"
                    defaultValue={initialData?.mail}
                    placeholder="例) info@invoicy-cloud.com"
                    type="text"
                    {...register("mail", {
                      required: false,
                    })}
                  />
                </FormGroup>
              </Col>
            </Row> */}
            <Row>
              <Col lg="9">
                <label className="form-control-label" htmlFor="signature">
                  印影
                </label>
                <Dropzone
                  id="signature"
                  setData={setSignatureFile}
                  defaultValue={signaturePreSignedUrl}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <hr className="my-4" />
      {/* <h6 className="heading-small text-muted mb-4">品目明細</h6> */}
      <div className="mb-4">
        <Row>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="reportMonth">
                報告月
              </label>
              <input
                className="form-control"
                id="reportMonth"
                type="month"
                value={reportMonth}
                onChange={(e) => setReportMonth(e.target.value)}
                // {...register("issueDate", {
                //   required: false,
                // })}
              />
            </FormGroup>
            <FormGroup>
              <Button
                className="btn-icon"
                color="secondary"
                type="button"
                onClick={() => setModal(true)}
              >
                <i class="fas fa-save"></i>
                <span className="">一括編集</span>
              </Button>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <div className="table-responsive">
              <Table
                className={`align-items-center table-flush ${styles.tableDetail}`}
                bordered={true}
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">日付</th>
                    <th scope="col">開始</th>
                    <th scope="col">終了</th>
                    <th scope="col">休憩</th>
                    <th scope="col">作業時間</th>
                    <th scope="col">作業内容・備考</th>
                  </tr>
                </thead>
                <>
                  <tbody className="list">
                    {items.length > 0 &&
                      items.map((item, i) => (
                        <tr key={i} className={styles.draggable}>
                          <td>
                            <Input
                              placeholder="日付"
                              type="text"
                              disabled
                              value={item.date}
                            />
                          </td>
                          <td>
                            <Input
                              // defaultValue="00:00"
                              placeholder=""
                              type="time"
                              className="text-left"
                              onChange={(e) =>
                                handleChangeInput("start", e.target.value, i)
                              }
                              value={item.start}
                            />
                          </td>
                          <td class="align-top">
                            <Input
                              placeholder=""
                              // defaultValue="00:00"

                              type="time"
                              onChange={(e) =>
                                handleChangeInput("end", e.target.value, i)
                              }
                              value={item.end}
                            />
                          </td>
                          <td>
                            <Input
                              // defaultValue="00:00"
                              type="time"
                              className="text-left"
                              onChange={(e) =>
                                handleChangeInput("break", e.target.value, i)
                              }
                              value={item.break}
                            />
                          </td>
                          <td>
                            <Input
                              defaultValue="00:00"
                              type="text"
                              onChange={(e) =>
                                handleChangeInput(
                                  "dailyTotal",
                                  e.target.value,
                                  i
                                )
                              }
                              value={item.dailyTotal}
                            ></Input>
                          </td>
                          <td>
                            <Input
                              rows={1}
                              type="textarea"
                              className="text-left"
                              value={item.note}
                              onChange={(e) =>
                                handleChangeText("note", e.target.value, i)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="totalWorkingDays"
                          >
                            作業日数
                          </label>
                          <input
                            className="form-control"
                            id="totalWorkingDays"
                            type="text"
                            disabled
                            value={totalWorkingDays}
                            // {...register("mail", {
                            //   required: false,
                            // })}
                          />
                        </FormGroup>
                      </td>
                      <td colSpan="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="totalWorkingDays"
                          >
                            作業時間
                          </label>
                          <input
                            className="form-control"
                            id="totalWorkingDays"
                            type="text"
                            disabled
                            value={totalWorkingHours}
                            // {...register("mail", {
                            //   required: false,
                            // })}
                          />
                        </FormGroup>
                      </td>
                      <td colSpan="2"></td>
                    </tr>
                  </tfoot>
                </>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
      <div className="">
        <FormGroup>
          <label className="form-control-label" htmlFor="note">
            備考
          </label>
          <textarea
            id="note"
            className="form-control"
            placeholder=""
            rows="4"
            type="textarea"
            {...register("note", {
              required: false,
            })}
          />
        </FormGroup>
      </div>
      <ReportFormModal
        modal={modal}
        setModal={setModal}
        handleApplyInput={handleApplyInput}
      />
    </>
  );
};

export default ReportForm;
