import React, { useLayoutEffect, useEffect, useRef, useContext } from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import { AuthContext } from "../providers/AuthProvider";
import { useTracking } from "../hooks/useTracking";
import { login, useAuth } from "../firebase";
import routes from "routes.js";

function Auth({ children }) {
  useTracking("G-PP2TVZX77J");
  const location = useLocation();
  const context = useContext(AuthContext);
  const { currentUser, isLoading } = useAuth();
  const mainContentRef = useRef(null);

  useLayoutEffect(() => {
    context.setUserInfo(currentUser);
    context.setAuthLoading(isLoading);
  }, [currentUser]);
  useEffect(() => {
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    // mainContentRef.current.scrollTop = 0;
    document.body.classList.add("bg-default");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove("bg-default");
    };
  }, []);
  useEffect(() => {
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    // mainContentRef.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  if (isLoading) {
    return <div></div>;
  }
  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <AuthNavbar />
        {children}
      </div>
      <AuthFooter />
    </>
  );
}

export default Auth;
