import React, { useState, useEffect, useContext, useMemo } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import {
  Text,
  View,
  PDFDownloadLink,
  Page,
  Document,
} from "@react-pdf/renderer";
import axios from "axios";
import AdminLayout from "layouts/Admin.js";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useForm, Controller } from "react-hook-form";
import compose from "../../../styles/compose";
import styles from "./InvoiceReview.module.scss";
import Invoice from "../../../components/Pdf/Invoice";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { db, storageRef, useAuth } from "../../../firebase";
import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  where,
  query,
} from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { AuthContext } from "../../../providers/AuthProvider";

const InvoiceReview = () => {
  const { id } = useParams();
  const [invoiceId, setInvoiceId] = useState();
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState();
  const [currentUser, setCurrentUser] = useState();
  const context = useContext(AuthContext);

  //ログイン情報格納処理
  useEffect(() => {
    if (!context?.userInfo?.uid) {
      return <></>;
    }
    setCurrentUser(context.userInfo);
  }, [context]);

  const handleCopyInvoice = async () => {
    const { id, invoiceNumber, ...d } = data;
    const newData = { ...d, copy: true };
    history.push(`/invoices/new`, { invoiceData: newData });
  };
  const handleToEstimate = async () => {
    const { id, ...d } = data;
    const newData = { ...d, copy: true };
    history.push(`/estimates/new`, { invoiceData: newData });
  };

  const handleDeleteInvoice = async () => {
    try {
      const invoicesCollectionRef = collection(
        db,
        "docs",
        currentUser.uid,
        "invoices"
      );
      await deleteDoc(doc(invoicesCollectionRef, invoiceId))
        .then((d) => history.push(`/invoices`))
        .catch((e) => console.error(e));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInvoiceData = async () => {
    try {
      const invoicesCollectionRef = collection(
        db,
        "docs",
        currentUser.uid,
        "invoices"
      );
      const q = query(invoicesCollectionRef, where("id", "==", id));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.docs.length) {
        history.push("/error/404");
      }
      querySnapshot.forEach(async (doc) => {
        const d = doc.data();
        setInvoiceId(doc.id);

        setData({ ...d });
      });
    } catch (error) {
      console.log("error ", error);
    }
  };

  useEffect(() => {
    if (currentUser) {
      if (location?.state?.invoiceData) {
        setData(location.state.invoiceData);
      } else {
        fetchInvoiceData();
      }
    }
  }, [currentUser]);

  // useEffect(async () => {
  //   if (data?.signatureUrl) {
  //     const signaturePresignedUrl = await getPresignedUrl(data.signatureUrl);
  //     setSignaturePreSignedUrl(signaturePresignedUrl);
  //   }
  // }, [data?.signatureUrl]);

  const InvoicePDF = useMemo(() => <Invoice data={data} title="請求書" />, [
    data,
  ]);
  return (
    <AdminLayout>
      {data ? (
        <Container className="mt-6 mt-xl-2" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className={styles.cardHeader}>
                  <Row className="align-items-center">
                    <Col xs="4">
                      <h3 className="mb-0">請求書</h3>
                    </Col>
                    <Col className="text-right" xs="8">
                      {/* <Button color="warning" size="sm" onClick={() => testMail()}>
                  test
                </Button> */}
                      <Button
                        className="btn-icon  d-none d-md-inline"
                        color="primary"
                        type="button"
                        onClick={() => history.push(`/invoices/${id}/print`)}
                      >
                        <i class="fas fa-print"></i>
                        <span className="btn-inner--text ml-0">印刷</span>
                      </Button>
                      <PDFDownloadLink
                        className=" d-inline d-md-inline"
                        document={<Invoice data={data} title="請求書" />}
                        fileName={`${
                          data.invoiceNumber
                            ? "[請求書]" + data.invoiceNumber
                            : "請求書"
                        }.pdf`}
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Button
                              className="btn-icon"
                              color="primary"
                              type="button"
                              size=""
                            >
                              <i class="fas fa-file-download"></i>
                              <span className="d-inline d-sm-inline btn-inner--text ml-0">
                                保存
                              </span>
                            </Button>
                          ) : (
                            <Button
                              className="btn-icon"
                              color="primary"
                              type="button"
                              size=""
                            >
                              <i class="fas fa-file-download"></i>
                              <span className="d-inline d-sm-inline btn-inner--text ml-0">
                                保存
                              </span>
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                      <UncontrolledDropdown group className="ml-2">
                        <DropdownToggle color="primary" size="" className="">
                          <i class="fas fa-ellipsis-h"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                          {/* <DropdownItem
                            className="d-sm-block d-md-none"
                            onClick={() =>
                              history.push(`/invoices/${id}/print`)
                            }
                          >
                            印刷する
                          </DropdownItem> */}
                          <DropdownItem
                            onClick={(e) =>
                              history.push(`/invoices/${id}/edit`)
                            }
                          >
                            編集する
                          </DropdownItem>
                          <DropdownItem onClick={handleCopyInvoice}>
                            同じ内容で請求書を複製
                          </DropdownItem>
                          <DropdownItem onClick={handleToEstimate}>
                            同じ内容で見積書を作成
                          </DropdownItem>
                          <DropdownItem onClick={handleDeleteInvoice}>
                            削除する
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody className={styles.cardBody}>
                  <div className={styles.containerPdf}>
                    <Invoice data={data} title="請求書" />
                  </div>
                </CardBody>
                <CardFooter className={styles.stickyFooter}></CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      ) : (
        <p></p>
      )}
    </AdminLayout>
  );
};

export default InvoiceReview;
