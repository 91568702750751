import React, { useLayoutEffect, useEffect, useRef, useContext } from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

// core components
import ErrorNavbar from "components/Navbars/ErrorNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import { useTracking } from "../hooks/useTracking";
function ErrorLayout({ children }) {
  useTracking("G-PP2TVZX77J");
  const mainContentRef = useRef(null);
  useEffect(() => {
    document.body.classList.add("bg-default");

    return function cleanup() {
      document.body.classList.remove("bg-default");
    };
  }, []);

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <ErrorNavbar />
        {children}
      </div>
      <AuthFooter />
    </>
  );
}

export default ErrorLayout;
