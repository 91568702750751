import InvoicesList from "views/pages/invoices/InvoicesList.js";
import EstimatesList from "views/pages/estimates/EstimatesList";
import WorkReportsList from "views/pages/reports/WorkReportsList";
const routes = [
  {
    path: "/invoices",
    name: "請求書",
    icon: "fas fa-file-alt text-primary",
    component: InvoicesList,
    layout: "",
  },
  {
    path: "/estimates",
    name: "見積書",
    icon: "fas fa-file-alt text-primary",
    component: EstimatesList,
    layout: "",
  },
  {
    path: "/reports",
    name: "作業報告書",
    icon: "fas fa-file-alt text-primary",
    component: WorkReportsList,
    layout: "",
  },
  // {
  //   path: "/nouhin",
  //   name: "納品書",
  //   icon: "fas fa-file-alt text-primary",
  //   component: InvoicesList,
  //   layout: "",
  // },
];

export default routes;
