import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

import Login from "views/pages/auth/Login.js";
import Register from "views/pages/auth/Register.js";
import Password from "views/pages/auth/Password.js";
import PasswordReset from "views/pages/auth/PasswordReset.js";
import IndexView from "views/Index.js";
import TransactionLaw from "views/pages/transactionLaw.js";
import PrivacyPolicy from "views/pages/privacyPolicy.js";
import Terms from "views/pages/terms.js";

import InvoiceCreate from "views/pages/invoices/InvoiceCreate";
import InvoiceEdit from "views/pages/invoices/InvoiceEdit";
import InvoiceShow from "views/pages/invoices/InvoiceShow";
import InvoiceReview from "views/pages/invoices/InvoiceReview";
import InvoicesList from "views/pages/invoices/InvoicesList";

import EstimateCreate from "views/pages/estimates/EstimateCreate";
import EstimateEdit from "views/pages/estimates/EstimateEdit";
import EstimateShow from "views/pages/estimates/EstimateShow";
import EstimateReview from "views/pages/estimates/EstimateReview";
import EstimatesList from "views/pages/estimates/EstimatesList";

import WorkReportCreate from "views/pages/reports/WorkReportCreate";
import WorkReportEdit from "views/pages/reports/WorkReportEdit";
import WorkReportShow from "views/pages/reports/WorkReportShow";
import WorkReportReview from "views/pages/reports/WorkReportReview";
import WorkReportsList from "views/pages/reports/WorkReportsList";
import Error404 from "views/pages/errors/Error404";
import { AuthProvider } from "./providers/AuthProvider";


ReactDOM.render(
  <AuthProvider>
    <BrowserRouter>
      <Switch>
        {/* <AdminLayout> */}
        <Route exact path="/invoices/new" component={InvoiceCreate} />
        <Route exact path="/invoices/:id/review" component={InvoiceReview} />
        <Route exact path="/invoices/:id/edit" component={InvoiceEdit} />
        <Route exact path="/invoices/:id/print" component={InvoiceShow} />
        <Route exact path="/invoices" component={InvoicesList} />

        <Route exact path="/estimates/new" component={EstimateCreate} />
        <Route exact path="/estimates/:id/review" component={EstimateReview} />
        <Route exact path="/estimates/:id/edit" component={EstimateEdit} />
        <Route exact path="/estimates/:id/print" component={EstimateShow} />
        <Route exact path="/estimates" component={EstimatesList} />

        <Route exact path="/reports/new" component={WorkReportCreate} />
        <Route exact path="/reports/:id/review" component={WorkReportReview} />
        <Route exact path="/reports/:id/edit" component={WorkReportEdit} />
        <Route exact path="/reports/:id/print" component={WorkReportShow} />
        <Route exact path="/reports" component={WorkReportsList} />
        {/* </AdminLayout>
      <AuthLayout> */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/password" component={Password} />
        <Route exact path="/password-reset" component={PasswordReset} />
        {/* </AuthLayout> */}
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/transaction" component={TransactionLaw} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route exact path="/error/404" component={Error404} />
        <Route path="/" render={(props) => <IndexView {...props} />} />
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  </AuthProvider>,
  document.getElementById("root")
);
