import React from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardImgOverlay,
  CardTitle,
  Collapse,
} from "reactstrap";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import IndexFooter from "components/Footers/IndexFooter.js";
const Terms = () => {
  return (
    <>
      <IndexNavbar />
      <div className="main-content mt-40">
        <section className="py-6 pb-9 bg-white">
          <Container fluid className="docs">
            <Row className="justify-content-center">
              <Col md="8">
                <h2 className="display-4">利用規約</h2>
                <hr />
                <p>
                  INVOICYは、下記の利用規約に同意された方のみ提供させていただくサービスです。当サービスを利用することで、下記の利用規約に同意していただいたこととみなさせていただきますのでご注意ください。
                </p>
                <div>
                  <h4>第1条（目的）</h4>
                  <ol>
                    <li>
                      「INVOICY」利用規約（以下「本規約」といいます。）は、当社及び本規約に基づいて本サイト上で提供する「INVOICY」、その他当社が提供するサービス（以下総称して「本サービス」といいます。）を利用するお客様に適用されます。
                    </li>
                    <li>
                      本規約は、当社が、当社ウェブサイト上に本サービスに関して個別規定や追加規定を掲載する場合、それらは本規約の一部を構成するものとし、個別規定又は追加規定が本規約と抵触する場合には、個別規定又は追加規定が優先するものとします。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第2条（定義）</h4>
                  <p>
                    本規約において、次の各号に掲げる用語の意義は、当該各号に定めるところに従います。
                  </p>
                  <ol>
                    <li>
                      <h4>アカウント</h4>
                      <p>
                        利用者が本サービスを利用するための資格又は権利をいいます。
                      </p>
                    </li>
                    <li>
                      <h4>コンテンツ</h4>
                      <p>
                        利用者が本サービスを通じてアクセスすることができる情報（文章、画像、動画、音声、音楽その他のサウンド、イメージ、ソフトウェア、プログラム、コードその他のデータを含みますが、これらに限られません。）の総称をいいます。
                      </p>
                    </li>
                    <li>
                      <h4>当社ウェブサイト</h4>
                      <p>当社の運営するウェブサイトをいいます。</p>
                    </li>
                    <li>
                      <h4>本利用契約</h4>
                      <p>
                        本規約に定める手続により、当社と申込者との間で締結される本サービスの利用に関する契約をいいます。
                      </p>
                    </li>
                    <li>
                      <h4>申込者</h4>
                      <p>
                        本サービスの利用登録の申込みを行う事業者（法人その他の団体及び事業として又は事業のために契約の当事者となる場合における個人をいいます。以下同じです。）をいいます。
                      </p>
                    </li>
                    <li>
                      <h4>利用者</h4>
                      <p>
                        本規約に定める手続により本利用契約を締結のうえ、本サービスの利用登録を行った事業者をいいます。
                      </p>
                    </li>
                    <li>
                      <h4>連携サービス</h4>
                      <p>
                        第三者が運営・管理するサイトのうち当社が任意に指定するサイトをいい、利用者又は利用者に関連する者の①会計情報に関するサイト、②売上情報に関するサイト、③銀行口座、④カード情報、等に関するサイトを含みますが、これらに限られません。
                      </p>
                    </li>
                    <li>
                      <h4>連携サービスID</h4>
                      <p>
                        連携サービスパスワードと組み合わせて、利用者とその他の者とを識別するために用いられる符号をいいます。
                      </p>
                    </li>
                    <li>
                      <h4>連携サービスパスワード</h4>
                      <p>
                        連携サービスIDと組み合わせて、利用者とその他の者とを識別するために用いられる符号をいいます。
                      </p>
                    </li>
                    <li>
                      <h4>カンパニー</h4>
                      <p>
                        一つ又は複数のアカウントにより共同で本サービスの利用をする際のチームをいいます。
                      </p>
                    </li>
                    <li>
                      <h4>オーナー</h4>
                      <p>
                        カンパニーの管理者として、同カンパニーへのメンバーの追加や削除の権限を有する利用者をいいます。
                      </p>
                    </li>
                    <li>
                      <h4>メンバー</h4>
                      <p>
                        オーナーによってカンパニーへ追加された利用者をいいます。
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第3条（本規約への同意）</h4>
                  <ol>
                    <li>
                      利用者は、本規約に従って本サービスを利用するものとし、本規約に同意しない限り本サービスを利用しないものとします。
                    </li>
                    <li>
                      申込者が本規約に同意し、本サービスにおける利用登録を完了した時点で、当該申込者と当社との間で、本規約の諸規定に従った本利用契約が成立します。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第4条（利用登録）</h4>
                  <ol>
                    <li>
                      本サービスの利用を希望する申込者は、別途当社の定める手続に従い、本規約を遵守することに同意し、当社の定める方法に従い情報並びに書類（以下「利用者情報」といいます。）を当社に提供することにより、本サービスの利用登録の申請をすることができます。
                    </li>
                    <li>
                      当社は、申込者が以下の各号に該当すると当社が判断する場合その他当社の裁量により、申込者による本サービスの利用登録の申請を承認せず、又は承諾した利用登録を取り消す場合があります。申込者は、不承認又は利用登録の取消があった場合でも、当社に対し理由の開示をもとめ、又は異議の申立てを行うことはできません。
                      <br />
                      <br />
                      (1)
                      申込者が本サービスの利用登録の申請にあたって当社に提供した情報又は書類の全部又は一部につき、虚偽、誤り又は記載漏れがある場合
                      <br />
                      (2)
                      申込者が、本サービス又は当社が提供する別途のサービスにつき、サービス利用停止措置を受けたことがあり又は現在受けている場合、又はサービスの利用契約を解除されたことがある場合{" "}
                      <br />
                      (3)
                      過去に、本利用契約その他当社との間で締結した契約上の義務の履行を怠ったことがある場合、その他本利用契約上の義務の履行を怠るおそれがあると当社が判断した場合{" "}
                      <br />
                      (4)
                      申込者に対する本サービスの提供が当社の業務の支障、システムの不都合等を発生させるおそれがある場合{" "}
                      <br />
                      (5) その他、当社が申請を適当でないと判断した場合
                    </li>
                    <li>
                      当社が本サービスの利用登録の申請を承諾する場合には、その旨の通知を行います。当該通知が発信された時点で、当該申込者と当社との間で、本利用契約が成立するものとします。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第5条(本サービス利用のための情報提供)</h4>
                  <ol>
                    <li>
                      利用者は本サービスを利用するために、当社が指定する情報のうち全部または一部を当社に提供します。
                    </li>
                    <li>
                      前項の提供情報に連携サービスに関する情報が含まれる場合、利用者は、当社が連携サービスID、連携サービスパスワードを利用し、又は連携サービスを提供する第三者のAPI等のシステムを介して、当該連携サービスにアクセスすることを許諾するものとします。なお、当社が連携サービスID、連携サービスパスワードを利用し又はAPI接続をしたことにより利用者又は第三者に何らかの損害が生じたとしても、当社は何ら責任を負わないものとします。
                    </li>
                    <li>
                      利用者は、個人情報を当社に提供するにあたり、事前に書面等にて個人情報のご本人の同意を得た上で、いつでも当該同意書面等を当社に提出できるよう、保管・管理するものとし、当社の求めがあれば提出するものとします。
                    </li>
                    <li>
                      当社は、提供のあった個人情報については、ご本人の同意を得た上で当社に提供されたものとして取扱います。
                    </li>
                    <li>
                      カンパニーのメンバー及びオーナーは、本サービス上での行動情報が、同カンパニーの他のメンバーやオーナーにより利用されることを承諾します。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第6条（アカウントの管理）</h4>
                  <ol>
                    <li>
                      利用者は、アカウントを第三者に使用させず、譲渡、売買、買入等を行ってはならないものとします。当社は、アカウントの利用につき、当該アカウントの利用にかかるログインID
                      又はパスワードその他当該アカウントの利用につき当社が提示又は入力
                    </li>
                    <li>
                      利用者は、本規約に基づき付与されたアカウントの管理、使用について一切の責任を負うものとし、当社に損害を与えてはならないものとします。
                    </li>
                    <li>
                      利用者は、自己のアカウントの使用による本サービスの利用（第三者による使用を含みます）に関して、一切の債務を負担するものとします。
                    </li>
                    <li>
                      利用者は、利用者のハードウエア及びソフトウェアの保守を自らの責任と費用負担にて行い、利用者の端末環境を維持するものとします。
                    </li>
                    <li>
                      利用者は、ID等が盗まれたり、第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
                    </li>
                    <li>
                      利用者は、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。
                    </li>
                    <li>
                      カンパニーのオーナーは、同カンパニーにメンバーを追加する際に、同メンバーの行った操作に関して一切の責任を負うものとします。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第7条（禁止事項）</h4>
                  <p>
                    利用者は、本サービスの利用にあたって、以下に該当する行為又はそのおそれのある行為を行ってはならないものとします。
                  </p>
                  <ol>
                    <li>公序良俗に反する行為。</li>
                    <li>法令に違反する行為。</li>
                    <li>犯罪行為及び犯罪行為に結びつく行為。</li>
                    <li>当社に虚偽の情報を提供する行為。</li>
                    <li>
                      本サービスによりアクセス可能な情報を改ざんする行為。
                    </li>
                    <li>本サービスの運営を妨害するおそれのある行為。</li>
                    <li>
                      本サービスの利用に関連して当社から提供される情報その他のコンテンツにつき、当社が利用を許諾した範囲を越えてこれを利用し、又は公開する行為。
                    </li>
                    <li>
                      当社又は第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為。
                    </li>
                    <li>
                      本サービスが通常意図しないバグを利用する動作又は通常意図しない効果を及ぼす外部ツールの利用、作成又は頒布を行う行為。
                    </li>
                    <li>
                      コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為。
                    </li>
                    <li>
                      本サービスを逆アセンブル、逆コンパイル、リバースエンジニアリング、その他本サービスのソースコードを解析する行為。
                    </li>
                    <li>本サービスを複製、譲渡、貸与又は改変する行為。</li>
                    <li>
                      他の利用者のアカウントやパスワードを不正に使用する行為。
                    </li>
                    <li>第三者に不利益を与える行為。</li>
                    <li>その他当社が不適切と判断する行為。</li>
                  </ol>
                </div>
                <div>
                  <h4>第8条（規約違反の場合の措置等）</h4>
                  <ol>
                    <li>
                      当社は、利用者が次の各号の一に該当し又は該当するおそれがあると当社が判断した場合には、当社の裁量により、何らの通知も行うことなく、当該利用者に対し、利用者情報の全部若しくは一部の削除、本サービスの利用の一時停止若しくは制限、アカウントの削除又は本利用契約の解除等の措置（以下「利用停止等」といいます。）を講じることができるものとします。
                      <ul>
                        <li>本規約のいずれかの条項に違反した場合</li>
                        <li>
                          当社に提供された情報の全部又は一部に虚偽の事実があることが判明した場合
                        </li>
                        <li>
                          当社からの回答を求める連絡に対して30日間以上応答がない場合
                        </li>
                        <li>
                          本サービスの運営、保守管理上必要であると当社が判断した場合
                        </li>
                        <li>
                          その他前各号に類する事由があると当社が判断した場合
                        </li>
                      </ul>
                    </li>
                    <li>
                      利用者は、利用停止等後も、当社に対する本利用契約上の一切の義務及び債務（損害賠償債務を含むが、これに限る旨ではありません。）を免れるものではありません。
                    </li>
                    <li>
                      当社は、本条に基づき当社が行った行為により利用者に生じた不利益や損害について一切の責任を負わず、利用者のアカウント削除後も、当該利用者に関し当社が取得した情報を保有・利用することができるものとします。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第9条（利用者による退会）</h4>
                  <ol>
                    <li>
                      利用者は、当社所定の方法で当社に通知することにより、本利用契約を解約することができます。ただし、利用者が当社に債務を負担している場合、当該債務を弁済することを解約の条件とします。
                    </li>
                    <li>
                      前項の場合、当社は、当該利用者のアカウントを削除することができるものとします。
                    </li>
                    <li>
                      当社は、本条に基づく本利用契約の解約により利用者に生じた不利益や損害について一切の責任を負わず、利用者のアカウント削除後も、当該利用者に関し当社が取得した情報を保有・利用することができるものとします。
                    </li>
                    <li>
                      カンパニーのメンバーである利用者は、オーナーによりカンパニーから削除され、又は自ら退会した後も、当該利用者の同カンパニー内での行動情報が保有され、同カンパニーのメンバーやオーナーにより利用されることを承諾します。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第10条（本利用契約の終了）</h4>
                  <ol>
                    <li>
                      本利用契約が前二条により終了した場合その他理由の如何を問わず、利用者が本サービスを利用する権利を失った場合、利用者は、本サービスを利用することができなくなり、アカウント、利用者コンテンツその他本サービスに蓄積した情報を利用することができなくなることをあらかじめ承諾するものとします。
                    </li>
                    <li>
                      利用者は、本サービスの利用を終了した後も、当社及び第三者に対する本利用契約上の一切の義務及び債務（損害賠償を含みますが、これに限りません。）を免れるものではありません。
                    </li>
                    <li>
                      当社は、利用者が本サービスの利用を終了した後も、当該利用者に関し当社が取得した情報（利用者情報及び取引関連情報を含みますが、これに限りません。）を保有・利用することができるものとします。
                    </li>
                    <li>
                      カンパニーのメンバーである利用者は、オーナーが、利用者をカンパニーのメンバーから削除した場合、自動的に本サービスから退会させられることを承諾するものとします。
                    </li>
                    <li>
                      前項の場合、当該利用者は、本サービスを利用することができなくなり、アカウント、利用者コンテンツその他本サービスに蓄積した情報を利用することができなくなることをあらかじめ承諾するものとします。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第11条（連絡方法）</h4>
                  <ol>
                    <li>
                      本サービスに関する当社から利用者への連絡（本規約の変更又は追加に関する通知を含みますが、これらに限りません。）は、当社ウェブサイト内の適宜の場所への掲示、本サービス内での通知、電子メールの送信その他当社が適当と判断する方法により行うものとします。
                    </li>
                    <li>
                      当社が電子メールの送信による通知を行った場合、当社からの通知は、利用者が登録したメールアドレスにメールを送信することをもって、当該メールが通常到達すべきときに到達したものとみなします。
                    </li>
                    <li>
                      本サービスに関する問い合わせその他利用者から当社に対する連絡又は通知は、本サービス内における問い合わせフォーム又は当社ウェブサイト内の適宜の場所に表する本サービスに関する問い合わせアドレスへのメール送信その他当社が指定する方法により行うものとします。
                    </li>
                    <li>
                      カンパニーのメンバーである利用者は、オーナーが、利用者をカンパニーのメンバーから削除した場合、自動的に本サービスから退会させられることを承諾するものとします。
                    </li>
                    <li>
                      当社は、利用者が登録したメールアドレスその他の情報に基づき、本サービスに関する広告・宣伝等の連絡を行うことがあり、利用者はあらかじめこれに同意するものとします。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第12条（秘密保持）</h4>
                  <ol>
                    <li>
                      利用者は、本サービスに関連して当社が秘密である旨指定して開示した非公知の情報（以下「秘密情報」といいます。）を秘密に取り扱うものとします。
                    </li>
                    <li>
                      利用者は、秘密情報を厳重に保管・管理しなければならず、当社の事前の書面による承諾がある場合を除き、第三者に開示、漏洩しないものとします。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第13条（個人情報の取扱い）</h4>
                  <ol>
                    <li>
                      当社は、利用者情報、第5条に基づき取得した情報、その他利用者に関する情報に含まれる個人情報を別途当社ウェブサイトに掲示する「個人情報保護方針」に基づき、適切に取扱います。
                    </li>
                    <li>
                      当社は、「個人情報保護方針」に基づき、本サービスの提供及びこれに付随する業務に必要な範囲で前項の個人情報の取扱いを業務委託先に委託することができるものとします。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第14条（サービスの変更）</h4>
                  <ol>
                    <li>
                      当社は、当社の裁量により本サービスの一部の内容を追加又は変更することができます。当社は、本条に基づく本サービスの追加又は変更により、変更前の本サービスの全ての機能・性能が維持されることを保証するものではありません。
                    </li>
                    <li>
                      当社は、前項に基づいて本サービスを追加又は変更したことによりユーザーに生じた損害および不利益につき、一切の責任を負いません。
                    </li>
                    <li>
                      本サービスに関連してユーザー又は第三者に生じた損害については、当社に故意又は重過失があった場合を除き、当社は責任を負わないものとします。また、当社は、現実に発生した直接かつ通常の範囲内の損害（逸失利益、間接損害、特別損害、懲罰的損害を含みません）に限り、かつ、有料サービスにおいては代金額（継続的なサービスの場合は3か月分相当額）を上限として損害賠償責任を負うことのほかは、一切の責任を負わないものとします。上記の免責が消費者契約法その他の適用法令により無効とされる場合であっても、当社は、適用法令が許容する最大限の免責を受けるものとします。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第15条（利用者情報の取扱い）</h4>
                  <p>
                    当社は、ユーザーが本サービスで当社に対して提供する情報を、以下の目的により取得します。
                  </p>
                  <ol>
                    <li>
                      本サービス及び当社が提供するその他サービスに関する案内
                    </li>
                    <li>本サービスの停止・中止・契約解除の通知やその対応</li>
                    <li>
                      本サービスに関する当社の規約、ポリシー等の変更等の通知、また
                      紛争、訴訟などへの対応
                    </li>
                    <li>本サービスに関する問い合わせ等への対応のため</li>

                    <li>
                      前各号に付随する本サービスの提供・維持・改善・開発のため
                    </li>
                    <li>
                      本サービスと当社および当社関連会社が提供するサービス、または第三者サービス等との連携及びその機能向上に合理的な範囲内において当該第三者サービス等の提供元に契約者の情報を提供する場合
                    </li>
                    <li>
                      当社が、ユーザー情報の分析を行い、個別の企業や個人を特定できないように加工したものを作成したうえ、本サービスの改善、開発、研究、市場分析、マーケティング等の目的のために社内で利用、及び第三者に開示するため
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第16条（本サービスの終了・一時的な中断）</h4>

                  <ol>
                    <li>
                      当社は、当社の裁量で、本サービスを終了することができるものとします。
                    </li>
                    <li>
                      当社は、以下各号の事由が生じた場合には、本サービスの一部又は全部を一時的に中断することができるものとします。
                      <ul>
                        <li>システム等の保守を定期的に又は緊急に行う場合</li>
                        <li>
                          アクセス過多、その他予期せぬ要因でシステムに負荷が集中した場合
                        </li>
                        <li>利用者のセキュリティを確保する必要が生じた場合</li>
                        <li>電気通信事業者の役務が提供されない場合</li>
                        <li>第三者サービスの全部又は一部が提供されない場合</li>
                        <li>
                          火災、停電等により本サービスの提供ができなくなった場合
                        </li>
                        <li>
                          地震、噴火、洪水、津波等の天災により本サービスの提供ができなくなった場合
                        </li>
                        <li>
                          戦争、動乱、暴動、騒乱、労働争議等により本サービスの提供ができなくなった場合
                        </li>
                        <li>
                          法令又はこれらに基づく措置により本サービスの運営ができなくなった場合
                        </li>
                        <li>その他前各号に準じ当社が必要と判断した場合</li>
                      </ul>
                    </li>
                    <li>
                      当社は、前二項の規定により本サービスの運用を終了または中断するときは、事前に、本サービス上又は当社ウェブサイト上への掲示その他当社が適当と判断する方法で利用者に通知します。ただし、緊急でやむを得ない場合は、この限りでありません。
                    </li>
                    <li>
                      当社は、本条に基づき当社が行った措置により利用者に生じた損害について一切の責任を負わないものとします。
                    </li>
                    <li>
                      利用者は、本サービス終了後も、当社及び第三者に対する本利用契約上の一切の義務及び債務（損害賠償を含みますが、これに限りません。）を免れるものではありません。
                    </li>
                    <li>
                      当社は、本サービス終了後も、当社が取得した利用者に関し当社が取得した情報（登録企業情報及び取引関連情報を含みますが、これに限りません。）を保有・利用することができるものとします。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第17条（本規約の変更）</h4>
                  <ol>
                    <li>
                      当社は、利用者の事前の承諾を得ることなく本規約を随時変更することができるものとします。変更後の本規約は、当社が別途定める場合を除いて、当社ウェブサイトに掲示された時点より効力を生じます。
                    </li>
                    <li>
                      本サービスの利用条件等は、変更後の本規約によるものとし、利用者は、変更後の本規約に同意しない場合には、直ちに本サービスの利用を終了するものとします。
                    </li>
                    <li>
                      利用者が本規約の変更後も本サービスの利用を継続する場合、当該利用者は、変更後の規約に同意したものとみなされます。利用者は、自己の責任において、随時、本規約の最新の内容を確認の上、本サービスを利用するものとします。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第18条（知的財産権等の帰属）</h4>
                  <ol>
                    <li>
                      当社は、利用者の事前の承諾を得ることなく本規約を随時変更することができるものとします。変更後の本規約は、当社が別途定める場合を除いて、当社ウェブサイトに掲示された時点より効力を生じます。
                    </li>
                    <li>
                      当社は、利用者に対し、当社ウェブサイト並びに本サービス及び提供コンテンツにつき、本サービスの利用に必要な範囲における非独占的な利用を許諾します。ただし、かかる利用許諾は、第三者に対し再使用許諾する権利を含むものではなく、また、利用者に対し、当社ウェブサイト並びに本サービス及び提供コンテンツについての知的財産権、所有権類似の権利又は自由に処分しうる権利その他の権利の譲渡又は付与を意味するものではありません。
                    </li>
                    <li>
                      当社ウェブサイト上、当社の商標、ロゴ及びサービスマーク等（以下、総称して「商標等」といいます。）が表示される場合がありますが、当社は、利用者その他の第三者に対し、商標等を譲渡し、又はその使用を許諾するものではありません。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第19条（免責）</h4>
                  <ol>
                    <li>
                      当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳ又はウェブブラウザのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、利用者はあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。
                    </li>
                    <li>
                      当社は、本サービスについて、完全性、継続性、特定目的適合性、電子帳簿保存法における要件適合性その他いかなる保証もするものではなく、通信回線の障害その他事由の如何を問わず利用者が本サービスの提供を受けられなかったときでも、何ら責任を負わないものとします。
                    </li>
                    <li>
                      何らかの理由により当社が責任を負う場合であっても、当社は、付随的損害、間接損害、特別損害及び逸失損害については、賠償する責任を負わないものとします。
                    </li>
                    <li>
                      本サービスでは
                      請求書などの発行の他、請求書などの郵送手続きを代行しますが、請求書発送先への郵送は委託先のサービスを利用します。そのため、委託先企業にてで遅滞が発生したことを起因とする損害については、当社は何ら責任を負わないものとします。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第20条（損害賠償）</h4>
                  <ol>
                    <li>
                      利用者は、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。
                    </li>
                    <li>
                      当社は、本サービスに関連して利用者その他の第三者が被った損害について、一切賠償の責任を負いません。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第20条（損害賠償）</h4>
                  <ol>
                    <li>
                      利用者は、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。
                    </li>
                    <li>
                      当社は、本サービスに関連して利用者その他の第三者が被った損害について、一切賠償の責任を負いません。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第21条（本利用契約の譲渡等）</h4>
                  <ol>
                    <li>
                      利用者は、当社の書面による事前の承諾がある場合を除き、本利用契約に基づく利用者の権利若しくは義務、又は本利用契約上の地位について、第三者への譲渡、承継、担保設定、その他一切の処分をすることはできません。
                    </li>
                    <li>
                      当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い本利用契約に基づく利用者の権利若しくは義務、又は本利用契約上の地位、並びに利用者情報、第5条に定める情報その他一切の利用者に関する情報を当該事業譲渡の譲受人に譲渡することができるものとし、利用者は、かかる譲渡につき本項において予め同意します。なお、本項にいう事業譲渡には、会社分割その他事業が移転するあらゆる場合を含むものとします。
                    </li>
                  </ol>
                </div>
                <div>
                  <h4>第22条（分離可能性）</h4>
                  <p>
                    本規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及び利用者は、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
                  </p>
                </div>
                <div>
                  <h4>第23条（協議解決）</h4>
                  <p>
                    本規約に規定のない事項又は本規約の解釈について疑義が生じた事項については、お客様及び当社は、誠意をもって協議の上、解決にあたるものとします。
                  </p>
                </div>
                <div className="text-right">
                  <p>
                    制定：2022年1月29日
                    <br />
                    最終改定：2022年1月29日
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>

      <IndexFooter />
    </>
  );
};

export default Terms;
