import { Link } from "react-router-dom";
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
import styles from "./IndexHeader.module.scss";

function IndexHeader() {
  return (
    <>
      <div className="header bg-default pt-5 pb-7 mt-4">
        <Container>
          <div className="header-body ">
            <Row className="align-items-center">
              <Col lg="5">
                <div className="pr-sm-5 mt-5">
                  <h1 className="display-4 text-white font-weight-bold">
                    <strong>完全無料</strong>
                    <br /> フリーランス向け
                    <br />
                    請求書、作業報告書管理ツール
                  </h1>
                  <div>
                    <img
                      alt="..."
                      className={styles.titleLogo}
                      src={require("assets/img/brand/logo_white.svg").default}
                    />
                  </div>

                  <p className="mt-4 text-white ">
                    INVOICY(インボイシークラウド)は、
                    完全無料で請求書、見積書、そしてフリーランスには欠かせない作業報告書の作成&管理を行うことができる
                    クラウドサービスです。
                  </p>
                  <div className="mt-5">
                    <Button
                      className="btn-neutral my-2 w-100"
                      color="default"
                      to="/register"
                      tag={Link}
                    >
                      無料ではじめる
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg="7">
                <Row className="pt-5">
                  <Col md="12">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={
                        require("assets/img/theme/invoicy_fastview.png").default
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
